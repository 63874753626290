const Index = () => import( /* webpackChunkName: "weather" */ "./components/Index.vue");
const StationDetail = () => import( /* webpackChunkName: "weather" */ "./components/StationDetail.vue");
const DashboardForecast = () => import( /* webpackChunkName: "weather" */ "./components/DashboardForecast.vue");
const DetailsByLocation = () => import( /* webpackChunkName: "weather" */ "./components/DetailsByLocation.vue");

const ForecastsIndex  = () => import( /* webpackChunkName: "weather" */ "./components/forecast/forecastsIndex.vue");
const ForecastsDetails  = () => import( /* webpackChunkName: "weather" */ "./components/forecast/forecastDetails.vue");
const SelfRegistration = () => import( /* webpackChunkName: "weather" */ "./components/StationSelfRegistration.vue");

export const route = [{
    path: 'weather',
    name: 'weather',
    component: Index
  },
  {
    name: "weather:station",
    path: "weather/:stationId",
    meta: {
      track: false,
      featureFlag: "weather_stations"
    },
    props: true,
    component: StationDetail,
    children: []
  },
  {
    name: "weather:location",
    path: "weather/:longitude/:latitude/:name",
    meta: {
      track: false
    },
    props: true,
    component: DetailsByLocation,
    children: []
  },
  {
    path: 'forecasts',
    name: 'weather:forecasts',
    component: ForecastsIndex
  },
  {
    name: 'weather:forecasts:details',
    path: 'forecasts/:id',
    component: ForecastsDetails,
    props: true
  },
  {
    name: "weather:station:selfregistration:add",
    path: "stationregistration/add-station",
    component: SelfRegistration,
    props: true,
    meta: {
      featureFlag: "weather_stations_selfregister"
    },
  },
  {
    name: "weather:station:selfregistration:edit",
    path: "stationregistration/:stationId",
    component: SelfRegistration,
    props: true,
    meta: {
      featureFlag: "weather_stations_selfregister"
    },
  }
];


import "./module"; // Inizializzo la mappa per questo modulo
