import Vue from "vue"
import AppHeader from "./Header.vue";
import Sidebar from "./Sidebar.vue";
import SplitView from "@/components/SplitView.vue";
import mainMap from '@/app_modules/Map.vue';
import Component from "vue-class-component";
import AppFooter from "./Footer.vue";
import modalContainer from "src/components/modals/modalContainer.vue";
import { usersStoreGetters } from "@/store";

@Component({
  name: "full",
  components: {
    AppHeader,
    Sidebar,
    SplitView,
    mainMap,
    AppFooter,
    modalContainer
  }
})
export default class Full extends Vue {
  get name() {
    return this.$route.name;
  }
  get list() {
    return this.$route.matched;
  }
  get ready(){
    return this.$store.state.localization.ready;
  }
  userIsInRole(role: string){
    return usersStoreGetters.userIsInRole(role);
  }
}
