import { fillArray } from "@/store";
import { CreateActionsHandler, CreateGettersHandler } from "./UTILS";


export interface ProductionChainsStoreModel {
  productionChains: Array<server.productionChain>;
  _productionChains: Map<string, server.productionChain>;
}

interface ProductionChainsStoreActions {
  setProductionChains: (productionChains: server.productionChain[]) => void;
  removeProductionChain: (productionChainId: string) => void;
}

interface ProductionChainsStoreGetters {
  getProductionChains: () => server.productionChain[];
  getProductionChain: (productionChainId: string) => server.productionChain;
}

export const productionChainsStore = {
  PREFIX: "productionChains",
  namespaced: true,
  state: {
    productionChains: new Array<server.productionChain>(),
    _productionChains: new Map<string, server.productionChain>(),
  },
  getters: {
    getProductionChains: (state: ProductionChainsStoreModel) => (): server.productionChain[] => state.productionChains,
    getProductionChain: (state: ProductionChainsStoreModel) => (productionChainId: string): server.productionChain => state.productionChains.find(u => u.id === productionChainId),
  },
  mutations: {
    setProductionChains(state, productionChains: server.productionChain[]) {
      for (const key in productionChains) {
        const productionChain = productionChains[key];
        state._productionChains.set(productionChain.id, productionChain);
      }
      fillArray(state.productionChains, state._productionChains.values());
    },
    removeProductionChain(state, data: string) {
      state._productionChains.delete(data);
      fillArray(state.productionChains, state._productionChains.values());
    }
  },
  actions: {
    setProductionChains(context, productionChains: server.productionChain[]) {
      context.commit("setProductionChains", productionChains);
    },
    removeProductionChain(context, productionChainId: string) {
      context.commit("removeProductionChain", productionChainId);
    },
  }
}

export const productionChainsStoreActions = CreateActionsHandler<ProductionChainsStoreActions>(productionChainsStore);
export const productionChainsStoreGetters = CreateGettersHandler<ProductionChainsStoreGetters>(productionChainsStore);

