import { uiStoreActions, store } from "@/store";

declare var DEVELOPMENT: boolean;

class GeolocalizationService {

  async getCurrentPosition() {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        currentPosition => {
          uiStoreActions.updateUserPosition([currentPosition.coords.longitude,  currentPosition.coords.latitude])
        }, () => {
        }, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    }
  }
}

export const geolocalizationService = new GeolocalizationService();
