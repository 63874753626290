import { Validator } from 'vee-validate';
import moment from 'moment';
import Vue from 'vue';
import { CreateActionsHandler, CreateGettersHandler } from './UTILS';


export interface LocalizationStoreModel {
  labels: {};
  currentLanguage: string;
  supportedLanguages: string[];
}

interface LocalizationStoreActions {
  toggleLocalization: (value) => any;
  changeLanguage: ({ newLanguage, labels }) => any;
  initLanguage: () => any;
  setSupportedLanguages: (data) => any;
  setLanguageLabels: (labels) => any;
}

interface LocalizationStoreGetters {
  getLocalizedLabel: (key, group) => string;
  getCurrentLanguage: () => string;
}

export const localizationStore = {
  PREFIX: "localization",
  namespaced: true,
  state: {
    enabled: true,
    ready: false,
    labels: {},
    currentLanguage: "en",
    supportedLanguages: []
  },
  mutations: {
    //mutation to replace the localization labels in the store
    TOGGLE_LOCALIZATION(state, value) {
      state.enabled = value;
    },
    REPLACE_LABELS(state, newLabels) {
      for (var i = 0; i < newLabels.length; i++) {

        var ele = newLabels[i];
        //use set to make property observables
        Vue.set(state.labels, ele.stringCode, ele.stringValue);

        // VuexPersistance.Persist(state, "localizationStore");
      }

      state.ready = true;
    },
    //mutation to change current langugage
    SET_CURRENT_LANGUAGE(state, currentLanguage) {
      state.currentLanguage = currentLanguage;
      Validator.localize(currentLanguage);
      moment.locale(currentLanguage);

      // VuexPersistance.Persist(state, "localizationStore");
    },
    //mutation to change supported langugages
    SET_SUPPORTED_LANGUAGES(state, supportedLanguages) {
      state.supportedLanguages = supportedLanguages;

      // VuexPersistance.Persist(state, "localizationStore");
    }
  },
  actions: {
    toggleLocalization(context, value) {
      context.commit("TOGGLE_LOCALIZATION", value);

    },
    //this action need to be there, is far more easy to use a service from here (localization service) instead that using this vuex actions from a service
    changeLanguage(context, { newLanguage, labels }) {
      context.commit('SET_CURRENT_LANGUAGE', newLanguage);
      context.commit('REPLACE_LABELS', labels);
    },
    setSupportedLanguages(context, data) {
      context.commit('SET_SUPPORTED_LANGUAGES', data);
    },
    setLanguageLabels(context, labels) {
      context.commit('REPLACE_LABELS', labels);
    }

  },
  getters: {
    //return the value if exists, otherwise return the default
    getLocalizedLabel: (state, getters) => (key, group) => {
      if (!state.enabled) return key;

      if (typeof (group) != "undefined" && group)
        key = group + '.' + key;

      if (state.labels[key])
        return state.labels[key];

      return null;
    },
    getCurrentLanguage: (state) => () => {
      return state.currentLanguage;
    }
  }
}


export const localizationStoreActions = CreateActionsHandler<LocalizationStoreActions>(localizationStore);
export const localizationStoreGetters = CreateGettersHandler<LocalizationStoreGetters>(localizationStore);
