
import { baseRestService } from "@/services/_base/baseRestService";
import Config from "@/config"
import { usersStoreActions } from "@/store";

class ProductionChainsUsersServices extends baseRestService {
  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  public async List(userId: any): Promise<server.productionChainUserDetail[]> {
    var result = await this.Get<server.productionChainUserDetail[]>(`/users/${userId}/production-chain`);
    if (result) usersStoreActions.setUserProductionChains(result);
    return result;
  }

  public async Set(userId: string, productionChainId: string): Promise<server.productionChainUserDetail> {
    let result = await this.Post<server.productionChainUserDetail>(`/users/${userId}/production-chain`, { userId, productionChainId } as server.productionChainUserDetail);
    if (result) usersStoreActions.setUserProductionChains([result]);
    return result;
  }

  public async Remove(userId: string, productionChainId: string): Promise<boolean> {
    let obj = { userId, productionChainId: productionChainId };
    let result = await this.Delete<boolean>(`/users/${userId}/production-chain/${productionChainId}`, obj);
    if (result) usersStoreActions.removeUserProductionChains(obj);
    return result;
  }
}
export const productionChainsUsersServices = new ProductionChainsUsersServices();
