import { fillArray } from "@/store";
import { CreateActionsHandler, CreateGettersHandler } from "./UTILS";


export interface BulletinsStoreModel {
  bulletins: Array<server.bulletin>;
  tempImages: Map<string, Blob>;
  areals: Array<server.areal>;
}

interface BulletinsStoreActions {

  setBulletins: (bulletins: server.bulletin[]) => void;
  setBulletin: (bulletin: server.bulletin) => void;
  setTempImage: (image: { url: string, blob: Blob }) => void;
  removeBulletin: (bulletinId: string) => void;
  setAreals: (areals: server.areal[]) => void;
  setAreal: (areal: server.areal) => void;
}

interface BulletinsStoreGetters {
  getAreals: () => server.areal[];
  getAreal: (arealId: string) => server.areal;
  getBulletins: () => server.bulletin[];
  getBulletin: (bulletinId: string) => server.bulletin;
  getTempImage: (url: string) => Blob;
}

export const bulletinsStore = {
  PREFIX: "bulletins",
  namespaced: true,
  state: {
    bulletins: new Array<server.bulletin>(),
    _bulletins: new Map<string, server.bulletin>(),
    tempImages: new Map<string, Blob>(),
    areals: new Array<server.areal>()

  },
  getters: {
    getAreals: (state: BulletinsStoreModel) => (): server.areal[] => state.areals,
    getAreal: (state: BulletinsStoreModel) => (arealId): server.areal => state.areals.find(f => f.id === arealId),
    getBulletins: (state: BulletinsStoreModel) => (): server.bulletin[] => state.bulletins,
    getBulletin: (state: BulletinsStoreModel) => (bulletinId: string): server.bulletin => state.bulletins.find(u => u.id === bulletinId),
    getTempImage: (state: BulletinsStoreModel) => (url: string): Blob => state.tempImages.get(url)
  },
  mutations: {
    setAreals(state, areals: server.areal[]) {
      state.areals = areals
    },
    setAreal(state: BulletinsStoreModel, areal: server.areal) {
      let idx = state.areals.findIndex(i => i.id == areal.id);
      if (idx >= 0) state.areals[idx] = areal;
      else state.areals.push(areal);
    },
    setBulletins(state, bulletins: server.bulletin[]) {
      state._bulletins =new Map<string, server.bulletin>();
      for (const key in bulletins) {
        const bulletin = bulletins[key];
        state._bulletins.set(bulletin.id, bulletin);
      }
      fillArray(state.bulletins, state._bulletins.values());
    },
    setBulletin(state, bulletin: server.bulletin) {
      state._bulletins.set(bulletin.id, bulletin);
      fillArray(state.bulletins, state._bulletins.values());
    },
    setTempImage(state, data: any) {
      state.tempImages.set(data.url, data.blob);
    },
    removeBulletin(state, data: string) {
      state._bulletins.delete(data);
      fillArray(state.bulletins, state._bulletins.values());
    }
  },
  actions: {
    setAreals(context, areals: server.areal[]) {
      context.commit("setAreals", areals);
    },
    setAreal(context, areal: server.areal) {
      context.commit("setAreal", areal);
    },
    setBulletins(context, bulletins: server.bulletin[]) {
      context.commit("setBulletins", bulletins);
    },
    setBulletin(context, bulletin: server.bulletin) {
      context.commit("setBulletin", bulletin);
    },
    setTempImage(context, image: { url: string, blob: Blob }) {
      context.commit("setTempImage", image);
    },
    removeBulletin(context, bulletinId: string) {
      context.commit("removeBulletin", bulletinId);
    },
  }
}

export const bulletinsStoreActions = CreateActionsHandler<BulletinsStoreActions>(bulletinsStore);
export const bulletinsStoreGetters = CreateGettersHandler<BulletinsStoreGetters>(bulletinsStore);

