/* beautify ignore:start */
import Vue from "vue";
import Vuex from "vuex";

import { uiStore, UiStoreModel } from "@/store/ui.store";
import { weatherStore, WeatherStoreModel } from "@/store/weather.store";
import { usersStore, UsersStoreModel } from "@/store/users.store";
import { localizationStore, LocalizationStoreModel, } from "@/store/localizationStore";
import { stationStore, StationStoreModel } from "@/store/station.store";
import { BulletinsStoreModel, bulletinsStore } from "./bulletins.store";
import { SuggestionsStoreModel, suggestionsStore } from "./suggestions.store";
import { ProductionChainsStoreModel, productionChainsStore } from "./productionChain.store";
import { RolesStoreModel, rolesStore } from "./roles.store";


Vue.use(Vuex);

export interface AppModel {
  localization: LocalizationStoreModel
  userInterface: UiStoreModel,

  weather: WeatherStoreModel,
  weatherStations: StationStoreModel,

  users: UsersStoreModel,
  roles: RolesStoreModel

  bulletins: BulletinsStoreModel,
  suggestions: SuggestionsStoreModel

  productionChains: ProductionChainsStoreModel,
}


const _store = {
  modules: {
    [localizationStore.PREFIX]: localizationStore,
    [uiStore.PREFIX]: uiStore,
    [weatherStore.PREFIX]: weatherStore,
    [stationStore.PREFIX]: stationStore,
    [usersStore.PREFIX]: usersStore,
    [rolesStore.PREFIX]: rolesStore,
    [bulletinsStore.PREFIX]: bulletinsStore,
    [suggestionsStore.PREFIX]: suggestionsStore,
    [productionChainsStore.PREFIX]: productionChainsStore
  }
};

export const getStoreState = function (): AppModel {
  return store.state as any;
};

export const store = new Vuex.Store(_store as any);


