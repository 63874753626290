export default {
  data: function(){
    return {
      _isRendered: false
    }
  },
  updated() {
    // call the rendered method only once
    if(!this._isRendered && this.rendered){
      this._isRendered = true;
      this.rendered();
    }
  },
}
