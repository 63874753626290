import { fillArray, join } from "@/store";
import { CreateActionsHandler, CreateGettersHandler } from "./UTILS";
import { userRolesIntersection } from "./intersections/userRoles.intersection";
import { rolesStoreGetters } from "./roles.store";
import { bulletinsStoreGetters } from "./bulletins.store";
import { productionChainsStoreGetters } from "./productionChain.store";


export interface UsersStoreModel {
  currentUser: IUserDetail;
  users: IUserDetail[];
  _users: Map<string, IUserDetail>;
  userRoles: server.userRoleDetail[];
  _userRoles: Map<string, server.userRoleDetail>;
  userAreals: server.arealUserDetail[];
  _userAreals: Map<string, server.arealUserDetail>;
  userProductionChains: server.productionChainUserDetail[];
  _userProductionChains: Map<string, server.productionChainUserDetail>;
}

interface UsersStoreActions {
  setCurrentUser(data: server.userDetail);
  setUsers: (users: server.userDetail[]) => void;
  removeUser: (userId: string) => void;
  setUserRoles: (data: server.userRoleDetail[])  => void;
  removeUserRoles: (data: server.userRoleDetail)  => void;
  setUserAreals: (data: server.arealUserDetail[])  => void;
  removeUserAreals: (data: server.arealUserDetail)  => void;
  setUserProductionChains: (data: server.productionChainUserDetail[])  => void;
  removeUserProductionChains: (data: server.productionChainUserDetail)  => void;
}

interface UsersStoreGetters {
  currentUser(): IUserDetail;
  getUsers: () => IUserDetail[];
  getUser: (id: string) => IUserDetail;
  getUserRoles: (id: string) => server.roleDetail[];
  userIsInRole: (roleId: string, userId?: string, userName?: string) => boolean;
  getUserAreals: (id: string) => server.areal[];
  userIsInAreal: (arealId: string, userId?: string, userName?: string) => boolean;
  getUserProductionChains: (id: string) => server.productionChain[];
  userIsInProductionChain: (productionChainId: string, userId?: string, userName?: string) => boolean;
}

export const usersStore = {
  PREFIX: "users",
  namespaced: true,
  state: {
    currentUser: null,
    users: new Array<IUserDetail>(),
    _users: new Map<string, IUserDetail>(),
    userRoles: new Array<server.userRoleDetail>(),
    _userRoles: new Map<string, server.userRoleDetail>(),
    userAreals: new Array<server.arealUserDetail>(),
    _userAreals: new Map<string, server.arealUserDetail>(),
    userProductionChains: new Array<server.productionChainUserDetail>(),
    _userProductionChains: new Map<string, server.productionChainUserDetail>(),
  },
  getters: {
    currentUser: (state: UsersStoreModel) => () => { return state.currentUser; },
    getUsers: (state: UsersStoreModel) => (): IUserDetail[] => state.users,
    getUser: (state: UsersStoreModel) => (id: string): IUserDetail => state.users.find(u => u.id === id),
    getUserRoles: (state: UsersStoreModel) => (id: string): server.roleDetail[] => {
      let userRoles =  state.userRoles.filter(u => u.userId === id);
      return userRoles.map(m => rolesStoreGetters.getRole(m.roleId));
    },
    userIsInRole: (state: UsersStoreModel) => (roleId: string, userId: string = null, userName: string = null): boolean => {
      let user = Object.assign({}, state.currentUser);
      if(userId || userName) user = state.users.find(u => u.id === userId || u.userName == userName);
      return user.roles.map(x => x.id).indexOf(roleId) !== -1;
    },
    getUserAreals: (state: UsersStoreModel) => (id: string): server.areal[] => {
      let userAreals =  state.userAreals.filter(u => u.userId === id);
      return userAreals.map(m => bulletinsStoreGetters.getAreal(m.arealId));
    },
    userIsInAreal: (state: UsersStoreModel) => (arealId: string, userId: string = null, userName: string = null): boolean => {
      let user = Object.assign({}, state.currentUser);
      if(userId || userName) user = state.users.find(u => u.id === userId || u.userName == userName);
      return user.areals.map(x => x.id).indexOf(arealId) !== -1;
    },
    getUserProductionChains: (state: UsersStoreModel) => (id: string): server.productionChain[] => {
      let userProductionChains =  state.userProductionChains.filter(u => u.userId === id);
      return userProductionChains.map(m => productionChainsStoreGetters.getProductionChain(m.productionChainId));
    },
    userIsInProductionChain: (state: UsersStoreModel) => (productionChainsId: string, userId: string = null, userName: string = null): boolean => {
      let user = Object.assign({}, state.currentUser);
      if(userId || userName) user = state.users.find(u => u.id === userId || u.userName == userName);
      return user.productionChains.map(x => x.id).indexOf(productionChainsId) !== -1;
    }
  },
  mutations: {
    setCurrentUser(state: UsersStoreModel, data: server.userDetail) {
      state.currentUser = join(data)(userRolesIntersection);
    },
    setUsers(state: UsersStoreModel, datas: server.userDetail[]) {
      for (const key in datas) {
        const user = datas[key];
        state._users.set(user.id, join(user)(userRolesIntersection));
      }
      fillArray(state.users, state._users.values());
    },
    removeUser(state: UsersStoreModel, data: string) {
      state._users.delete(data);
      fillArray(state.users, state._users.values());
    },
    setUserRoles(state: UsersStoreModel, datas: server.userRoleDetail[]) {
      for (const key in datas) {
        const data = datas[key];
        state._userRoles.set(`${data.userId}_${data.roleId}`, data);
      }
      fillArray(state.userRoles, state._userRoles.values());
    },
    removeUserRoles(state: UsersStoreModel, data: server.userRoleDetail) {
      state._userRoles.delete(`${data.userId}_${data.roleId}`);
      fillArray(state.userRoles, state._userRoles.values());
    },
    setUserAreals(state: UsersStoreModel, datas: server.arealUserDetail[]) {
      for (const key in datas) {
        const data = datas[key];
        state._userAreals.set(`${data.userId}_${data.arealId}`, data);
      }
      fillArray(state.userAreals, state._userAreals.values());
    },
    removeUserAreals(state: UsersStoreModel, data: server.arealUserDetail) {
      state._userAreals.delete(`${data.userId}_${data.arealId}`);
      fillArray(state.userAreals, state._userAreals.values());
    },
    setUserProductionChains(state: UsersStoreModel, datas: server.productionChainUserDetail[]) {
      for (const key in datas) {
        const data = datas[key];
        state._userProductionChains.set(`${data.userId}_${data.productionChainId}`, data);
      }
      fillArray(state.userProductionChains, state._userProductionChains.values());
    },
    removeUserProductionChains(state: UsersStoreModel, data: server.productionChainUserDetail) {
      state._userProductionChains.delete(`${data.userId}_${data.productionChainId}`);
      fillArray(state.userProductionChains, state._userProductionChains.values());
    }
  },
  actions: {
    setCurrentUser(context, data: server.userDetail) {
      context.commit("setCurrentUser", data);
    },
    setUsers(context, users: server.userDetail[]) {
      context.commit("setUsers", users);
    },
    removeUser(context, userId: string) {
      context.commit("removeUser", userId);
    },
    setUserRoles(context, data: server.userRoleDetail[]) {
      context.commit("setUserRoles", data);
    },
    removeUserRoles(context, data: server.userRoleDetail) {
      context.commit("removeUserRoles", data);
    },
    setUserAreals(context, data: server.arealUserDetail[]) {
      context.commit("setUserAreals", data);
    },
    removeUserAreals(context, data: server.arealUserDetail) {
      context.commit("removeUserAreals", data);
    },
    setUserProductionChains(context, data: server.productionChainUserDetail[]) {
      context.commit("setUserProductionChains", data);
    },
    removeUserProductionChains(context, data: server.productionChainUserDetail) {
      context.commit("removeUserProductionChains", data);
    }
  }
}

export const usersStoreActions = CreateActionsHandler<UsersStoreActions>(usersStore);
export const usersStoreGetters = CreateGettersHandler<UsersStoreGetters>(usersStore);

