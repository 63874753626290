import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { messageService } from "@/services/_base/messageService";


@Component({})
export default class SplitView extends Vue {

  @Prop()
  loaderIndicator: false
  aspectRatio: number = 1;

  get isVertical() {
    return this.aspectRatio < 4/3;
  }

  slidePosition: number = 2;
  hasCommonSlot: boolean = false;

  slideLeft() {
    this.slidePosition--;
  }
  slideRight() {
    this.slidePosition++;
  }

  mounted() {
    this.hasCommonSlot = !!this.$slots.common;
    let $this = this;
    $this.aspectRatio = window.innerWidth / window.innerHeight;
    window.addEventListener('resize', () => {
      $this.$set($this,"aspectRatio",  window.innerWidth / window.innerHeight);
    })

    messageService.subscribeToChannel("resize-map", size => {
      this.slidePosition = size;
    })
  }
};


export class SplitViewHelper{

}
