const UsersManagement = () => import( /* webpackChunkName: "users-management" */ "./index.vue");


export const route = [
  {
    path: 'users-management',
    name: 'users-management',
    component: UsersManagement
  }
];
