import { fillArray } from "@/store";
import { CreateActionsHandler, CreateGettersHandler } from "./UTILS";


export interface RolesStoreModel {
  roles: server.roleDetail[];
}

interface RolesStoreActions {
  setRoles: (roles: server.roleDetail[]) => void;
  removeRole: (roleId: string) => void;
}

interface RolesStoreGetters {
  getRoles: () => server.roleDetail[];
  getRole: (id: string) => server.roleDetail;
}

export const rolesStore = {
  PREFIX: "roles",
  namespaced: true,
  state: {
    roles: new Array<server.roleDetail>(),
    _roles: new Map<string, server.roleDetail>(),
  },
  getters: {
    getRoles: (state: RolesStoreModel) => (): server.roleDetail[] => state.roles,
    getRole: (state: RolesStoreModel) => (id: string): server.roleDetail => state.roles.find(u => u.id === id),
  },
  mutations: {
    setRoles(state, roles: server.roleDetail[]) {
      for (const key in roles) {
        const role = roles[key];
        state._roles.set(role.id, role);
      }
      fillArray(state.roles, state._roles.values());
    },
    removeRole(state, data: string) {
      state._roles.delete(data);
      fillArray(state.roles, state._roles.values());
    }
  },
  actions: {
    setRoles(context, roles: server.roleDetail[]) {
      context.commit("setRoles", roles);
    },
    removeRole(context, roleId: string) {
      context.commit("removeRole", roleId);
    },
  }
}

export const rolesStoreActions = CreateActionsHandler<RolesStoreActions>(rolesStore);
export const rolesStoreGetters = CreateGettersHandler<RolesStoreGetters>(rolesStore);

