const Index = () => import( /* webpackChunkName: "bulletins" */ "./components/index.vue");
const BulletinEditor = () => import( /* webpackChunkName: "bulletins" */ "./components/bulletinEditor.vue");

export const route = [
  {
    path: 'bulletins/:productionChainId?',
    name: 'bulletins',
    component: Index,
    props: true
  },
  {
    name: "bulletins:addnew",
    path: "bulletins/addnew",
    meta: {
      track: true,
      featureFlag: "bulletin_editor"
    },
    props: false,
    component: BulletinEditor,
    children: []
  },
  {
    name: "bulletins:edit",
    path: "bulletins/edit/:bulletinId",
    meta: {
      track: true,
      featureFlag: "bulletin_editor"
    },
    props: true,
    component: BulletinEditor,
    children: []
  },
];
