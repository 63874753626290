import { MapHelper, MapColors } from "@/app_modules/MapHelper";
import { Esri, BindArrayToLayer } from "@/esriMap";
import { store, AppModel, fillArray, weatherStoreGetters } from "@/store";

import weatherstationPopupTemplateComponent from "./components/weatherstationPopupTemplate.vue"
import { localizeFunction } from "@/filters/localize";
import { weatherStationService } from "@/services";
import { WeatherStationIntersection } from "@/store/intersections/weatherstation.intersection";


// Create a symbol for drawing the point
export const weatherHightlightSymbol = {
  type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
  style: "circle",
  color: MapColors.genericPointBackground,
  size: "36px",
  outline: { // autocasts as new SimpleLineSymbol()
    color: MapColors.genericPoint,
    width: 2
  }
};

export const weatherStationSymbol = {
  type: "picture-marker",
  url: "/assets/img/pushpin_meteo.png",
  width: "35px",
  height: "35px"
}

export const weatherStationSymbolPessl = {
  type: "picture-marker",
  url: "/assets/img/pushpin_meteo_c2.png",
  width: "35px",
  height: "35px"
}

export const weatherStationSymbolRgu = {
  type: "picture-marker",
  url: "/assets/img/pushpin_meteo_c6.png",
  width: "35px",
  height: "35px"
}

export const STATIONS_LAYER = "StationsLayer";


export class StationLegendData {
  _currentMode: string = StationLegendData.MODE_symbol;
  get currentMode() { return this._currentMode; }
  set currentMode(value) {
    this._currentMode = value;
    filterWeatherStation();
  }

  updateMap() {
    filterWeatherStation();
  }

  get coloringMode() {
    switch (this.currentMode) {
      case StationLegendData.MODE_temperature:
      case StationLegendData.MODE_minTemperature:
      case StationLegendData.MODE_maxTemperature: return "temperature"
      case StationLegendData.MODE_leaf_wetness:
      case StationLegendData.MODE_umidity: return "humidity";
      case StationLegendData.MODE_rain24h:
      case StationLegendData.MODE_rain2days:
      case StationLegendData.MODE_rain7days: return "rain";
    }
    return null;
  }

  getCurrentValue(station: WeatherStationIntersection) {
    if (station.summary)
      switch (this.currentMode) {
        case StationLegendData.MODE_minTemperature: return station.summary().minTemperature;
        case StationLegendData.MODE_maxTemperature: return station.summary().maxTemperature;
        case StationLegendData.MODE_temperature: return station.summary().avgTemperature;
        case StationLegendData.MODE_umidity: return station.summary().avgRelativeHumidity;
        case StationLegendData.MODE_rain24h: return station.summary().precipitations;
        case StationLegendData.MODE_rain2days: return station.summary().precipitations2Days;
        case StationLegendData.MODE_rain7days: return station.summary().precipitations7Days;
      }
    return null;
  }

  getCurrentTextValue(station: WeatherStationIntersection) {
    let value = this.getCurrentValue(station);
    if (value)
      switch (this.currentMode) {
        case StationLegendData.MODE_minTemperature:
        case StationLegendData.MODE_maxTemperature:
        case StationLegendData.MODE_temperature: return `${value.toFixed(1)}°`
        case StationLegendData.MODE_umidity: return `${value.toFixed(0)}%`;
        case StationLegendData.MODE_rain24h:
        case StationLegendData.MODE_rain2days:
        case StationLegendData.MODE_rain7days: return `${value.toFixed(0)}`;
      }
    return "-";
  }

  getCurrentColorRange(station: WeatherStationIntersection) {
    let value = this.getCurrentValue(station);
    if (value)
      switch (this.currentMode) {
        case StationLegendData.MODE_temperature:
        case StationLegendData.MODE_minTemperature:
        case StationLegendData.MODE_maxTemperature: return getColorRangeFor_Temperature(value)
        case StationLegendData.MODE_leaf_wetness:
        case StationLegendData.MODE_umidity: return getColorRangeFor_Humidity(value);
        case StationLegendData.MODE_rain24h:
        case StationLegendData.MODE_rain2days:
        case StationLegendData.MODE_rain7days: return getColorRangeFor_Rain(value);
      }
    return null;
  }

  static MODE_symbol = "Simbolo"
  static MODE_temperature = "Temperatura media"
  static MODE_minTemperature = "Temperatura minima"
  static MODE_maxTemperature = "Temperatura massima"
  static MODE_leaf_wetness = "Bagnatura fogliare"
  static MODE_umidity = "Umidità"
  static MODE_rain24h = "Pioggia ultime 24h"
  static MODE_rain2days = "Pioggia ultime 48 ore"
  static MODE_rain7days = "Pioggia ultimi 7 giorni"
  static MODE_forecast_temperature = "forecast_temperature"
  static MODE_forecast_rain = "forecast_rain"
  static MODE_forecast_umidity = "forecast_umidity"
  static MODE_forecast_wind = "forecast_wind"
  static MODES = [
    StationLegendData.MODE_symbol,
    StationLegendData.MODE_maxTemperature,
    StationLegendData.MODE_temperature,
    StationLegendData.MODE_minTemperature,
    StationLegendData.MODE_leaf_wetness,
    StationLegendData.MODE_umidity,
    StationLegendData.MODE_rain24h,
    StationLegendData.MODE_rain2days,
    StationLegendData.MODE_rain7days,
    // StationLegendData.MODE_forecast_temperature,
    // StationLegendData.MODE_forecast_umidity,
    // StationLegendData.MODE_forecast_rain,
    // StationLegendData.MODE_forecast_wind
  ]
}

export var stationLegendData = new StationLegendData();


var Color, Color_TemperatureLow, Color_TemperatureMid, Color_TemperatureHigh, Color_HumidityLow, Color_HumidityHigh, Color_RainLow, Color_RainHigh, Color_NoData = null

Esri.ColorConstructor().then(r => {
  Color = r;
  Color_NoData = new Color(DomainColors.no_data);
  Color_TemperatureLow = new Color(DomainColors.temperature.low);
  Color_TemperatureMid = new Color(DomainColors.temperature.mid);
  Color_TemperatureHigh = new Color(DomainColors.temperature.high);
  Color_HumidityLow = new Color(DomainColors.humidity.low);
  Color_HumidityHigh = new Color(DomainColors.humidity.high);
  Color_RainLow = new Color(DomainColors.rain.low);
  Color_RainHigh = new Color(DomainColors.rain.high);
});

export var mapValueConfiguration = {

};


export var TMin = -20;
export var TMax = 40;
export var RainMin = 0;
export var RainMax = 500;
export var HumidityMin = 0;
export var HumidityMax = 100;


let ColorHelper: __esri.ColorConstructor = null;
Esri.ColorConstructor().then(r => {
  ColorHelper = r;
});


function getColorRangeFor_Temperature(value: number): number[] {
  let normval = Math.min(Math.max(TMin, value), TMax);
  // value deve andare da 0 ad 1. se sono più di 2 colori vanno fatti dei calcoli per adattare il colore sempre tra 0 ed 1

  if (ColorHelper) {
    let alpha = 200;
    let color = null;
    if (normval > 0)
      color = ColorHelper.blendColors(Color_TemperatureMid, Color_TemperatureHigh, normval / TMax);
    else
      color = ColorHelper.blendColors(Color_TemperatureMid, Color_TemperatureLow, Math.abs(normval) / TMin);
    color.a = alpha;
    return [color.r, color.g, color.b, color.a];
  }
  return [0, 0, 0, normval];
}


function getColorRangeFor_Rain(value: number): number[] {
  let normval = Math.min(Math.max(RainMin, value), RainMax);
  // value deve andare da 0 ad 1. se sono più di 2 colori vanno fatti dei calcoli per adattare il colore sempre tra 0 ed 1

  if (ColorHelper) {
    let alpha = 150;
    let color = ColorHelper.blendColors(Color_RainLow, Color_RainHigh, normval / RainMax);
    color.a = alpha;
    return [color.r, color.g, color.b, color.a];
  }
  return [0, 0, 0, normval];
}


function getColorRangeFor_Humidity(value: number): number[] {
  let normval = Math.min(Math.max(HumidityMin, value), HumidityMax);
  // value deve andare da 0 ad 1. se sono più di 2 colori vanno fatti dei calcoli per adattare il colore sempre tra 0 ed 1

  if (ColorHelper) {
    let alpha = 150;
    let color = ColorHelper.blendColors(Color_HumidityLow, Color_HumidityHigh, normval / HumidityMax);
    color.a = alpha;
    return [color.r, color.g, color.b, color.a];
  }
  return [0, 0, 0, normval];
}



var DomainColors = MapColors.weatherStations;


export function setMapWeatherStationsColors(colorBy) {
  filterWeatherStation();
}


// Il create layer non va esportato si chiama da solo
async function createLayers() {

  await MapHelper.WaitMapReady();
  await Esri.ColorConstructor();

  let weatherstatioPopupComponent = MapHelper.CreatePopupTemplate(weatherstationPopupTemplateComponent);

  let stationsLayer = await Esri.Layers.GraphicsLayer({
    id: STATIONS_LAYER, name: STATIONS_LAYER, title: "Stations", visible: true,
    // listMode: "hide"
  });

  await BindArrayToLayer(filterWeatherStation(), stationsLayer.graphics, async (s: WeatherStationIntersection) => {
    let curSymbol = weatherStationSymbol as any;
    switch (s.producer) {
      case "pessl": {
        curSymbol = weatherStationSymbolPessl
        break;
      }
      case "rgu": {
        curSymbol = weatherStationSymbolRgu
        break;
      }
    }

    if (stationLegendData.currentMode != StationLegendData.MODE_symbol) {
      curSymbol = {
        type: "cim",
        data: getPointSymbolData(s)
      }
    }

    let result = await Esri.Graphic({
      geometry: {
        type: "point", // autocasts as new Point()
        longitude: s.longitude,
        latitude: s.latitude
      },
      symbol: curSymbol
    });
    result.popupTemplate = await MapHelper.SetGraphicPopupTemplate(weatherstatioPopupComponent, localizeFunction("weather_station"), (c) => {
      c.$props.stationData = s;
    });
    return result;
  }, true);

  stationsLayer.watch("visible", async (e) => {
    if (!stationsLayer.graphics.length && e) {
      filterWeatherStation();
    }
  });

  //carico le stazioni di default a partire dalla homepage
  if (!stationsLayer.graphics.length) {
    filterWeatherStation();
  }

  await MapHelper.addLayer(stationsLayer);

  await MapHelper.WaitLayerReady(STATIONS_LAYER);
  MapHelper.goToLayer(STATIONS_LAYER);
}

createLayers();

function getPointSymbolData(s: WeatherStationIntersection) {
  return {
    type: "CIMPointSymbol",
    symbolLayers: [
      {
        type: "CIMVectorMarker",
        enable: true,
        size: 26,
        frame: { xmin: -5, ymin: -5, xmax: 5, ymax: 5 },
        scaleSymbolsProportionally: true,
        respectFrame: true,
        markerGraphics: [
          {
            type: "CIMMarkerGraphic",
            geometry: { x: 0, y: 0 },
            symbol: {
              type: "CIMTextSymbol",
              fontFamilyName: "Arial",
              fontStyleName: "Bold",
              height: 4,
              horizontalAlignment: "Center",
              symbol: {
                type: "CIMPolygonSymbol",
                symbolLayers: [
                  {
                    type: "CIMSolidFill",
                    enable: true,
                    color: [255, 255, 255, 255]
                  }
                ]
              },
              verticalAlignment: "Center"
            },
            textString: stationLegendData.getCurrentTextValue(s)
          }
        ],
      },
      {
        type: "CIMVectorMarker",
        enable: true,
        size: 30,
        frame: { xmin: 0.0, ymin: 0.0, xmax: 17.0, ymax: 17.0 },
        markerGraphics: [
          {
            type: "CIMMarkerGraphic",
            geometry: {
              rings: [
                [
                  [8.5, 0.2],
                  [7.06, 0.33],
                  [5.66, 0.7],
                  [4.35, 1.31],
                  [3.16, 2.14],
                  [2.14, 3.16],
                  [1.31, 4.35],
                  [0.7, 5.66],
                  [0.33, 7.06],
                  [0.2, 8.5],
                  [0.33, 9.94],
                  [0.7, 11.34],
                  [1.31, 12.65],
                  [2.14, 13.84],
                  [3.16, 14.86],
                  [4.35, 15.69],
                  [5.66, 16.3],
                  [7.06, 16.67],
                  [8.5, 16.8],
                  [9.94, 16.67],
                  [11.34, 16.3],
                  [12.65, 15.69],
                  [13.84, 14.86],
                  [14.86, 13.84],
                  [15.69, 12.65],
                  [16.3, 11.34],
                  [16.67, 9.94],
                  [16.8, 8.5],
                  [16.67, 7.06],
                  [16.3, 5.66],
                  [15.69, 4.35],
                  [14.86, 3.16],
                  [13.84, 2.14],
                  [12.65, 1.31],
                  [11.34, 0.7],
                  [9.94, 0.33],
                  [8.5, 0.2]
                ]
              ]
            },
            symbol: {
              type: "CIMPolygonSymbol",
              symbolLayers: [
                {
                  type: "CIMSolidFill",
                  enable: true,
                  color: s.summary ? stationLegendData.getCurrentColorRange(s) : [0, 0, 0, 10]
                }
              ]
            }
          }
        ],
        scaleSymbolsProportionally: true,
        respectFrame: true
      }
    ],
  }
}

const filteredWeatherStation: Array<server.station> = [];

export function filterWeatherStation(stations: WeatherStationIntersection[] = null) {
  filteredWeatherStation.splice(0, filteredWeatherStation.length);
  stations = stations ?? weatherStoreGetters.stations();
  return fillArray(filteredWeatherStation, stations);
  // filteredWeatherStation.splice(0, filteredWeatherStation.length);
  // var rows = weatherStoreGetters.filteredStations();
  // console.log(rows)
  // return fillArray(filteredWeatherStation, rows);

  // rows = rows.filter(s => (!this.filter || (s.name || "").toLowerCase().indexOf(filter.toLowerCase()) >= 0
  //   || (s.description || "").toLowerCase().indexOf(filter.toLowerCase()) >= 0)
  //   && (!filterByState || s.state === filterByState));
}
