const Index = () => import( /* webpackChunkName: "bulletins" */ "./components/index.vue");
const ArealEditor = () => import( /* webpackChunkName: "bulletins" */ "./components/arealDetail.vue");


export const route = [
  {
    path: 'areals',
    name: 'areals',
    component: Index
  },
  {
    name: "areals:addnew",
    path: "areals/addnew",
    meta: {
      track: true,
      featureFlag: "bulletin_editor"
    },
    props: false,
    component: ArealEditor,
    children: []
  },
  {
    name: "areals:edit",
    path: "areals/edit/:id",
    meta: {
      track: true,
      featureFlag: "bulletin_editor"
    },
    props: true,
    component: ArealEditor,
    children: []
  },
];
