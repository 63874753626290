import Component from "vue-class-component";
import Vue from "vue";
import { stationLegendData, StationLegendData } from "./weather/module";

@Component({})
export default class stationInfoLegend extends Vue {

  get modes() {
    return StationLegendData.MODES;
  }

  currentMode = stationLegendData.currentMode;

}