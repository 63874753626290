import Vue from "vue";

// modificate qui se volete attivare i servizi di sviluppo di vue
Vue.config.devtools = true
Vue.config.performance = false

// Mi assicuro che durante la pubblicazione i servizi di sviluppo di VUE siano disattivati.
if (!DEVELOPMENT) {
    Vue.config.devtools = false
    Vue.config.performance = false
}

import {
    Loader
} from "@/esriMap";
Loader.initialize();

import {
    store
} from "@/store";

// import dei filtri
import config from "./config";


// Initialize All Application Services
import {
    ServiceInizialization
} from "src/app_startup/services.ts";

import "./filters/localize";
import "./filters/dateFormat";
import "./filters/gravatar";
import "./filters/distance";
import "./filters/round";

import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
// import VueHighcharts from 'vue2-highcharts'
HighchartsMore(Highcharts);
// Vue.use(VueHighcharts);

import VeeValidate, { Validator } from "vee-validate";
import messagesIt from "vee-validate/dist/locale/it";
import messagesEn from "vee-validate/dist/locale/en";

Validator.localize("it", messagesIt);
Validator.localize("en", messagesEn);

Vue.use(VeeValidate, {
    locale: "it",
    fieldsBagName: "vfields",
    validity: true,
    dictionary: {
        en: {
            messages: messagesEn
        },
        it: {
            messages: messagesIt
        }
    }
});

import * as components from "./components";
import * as directives from "./directives";
const VueDynamicRegister = {
    install: function(Vue) {
        // Register components
        for (var component in components) {
            Vue.component(component, components[component]);
        }
        // Register directives
        for (var directive in directives) {
            Vue.directive(directive, directives[directive]);
        }
    }
};
Vue.use(VueDynamicRegister);

import SmartmeteoTemplate from "./SmartMeteo.vue";

//app insights: download the script and initialize the user context if user authenticated

import resize from "vue-resize-directive";
Vue.directive("resize", resize);

import rendered from "./mixins/renderedEvent";
import isvalid from "./mixins/isValid";
import doOnce from "./mixins/doOnce";
Vue.mixin(rendered);
Vue.mixin(isvalid);
Vue.mixin(doOnce);

import router from "./router";
//APP ROOT COMPONENT
//questo è il bootstrap vero e proprio della nostra applicazione Vue
export var App = null;
ServiceInizialization().then((result) => {
    setTimeout(() => {
        App = new Vue({
            el: "#Frontend",
            router,
            store,
            template: "<smartmeteo-template/>",
            components: {
                SmartmeteoTemplate
            }
        });
    }, 1000);
});
