import Vue from "vue";
import Component from "vue-class-component";
import { Esri, Draw, DrawParameters, Measure, MeasureParameters } from "@/esriMap";
import { OnDrawingComplete, MapHelper, OnDrawingReset } from "@/app_modules/MapHelper";
import { Watch } from "../../node_modules/vue-property-decorator";
import { localizeFunction } from "@/filters/localize";
import stationLegend from "./stationInfoLegend.vue";
@Component({
  components: {
    stationLegend
  }
})
export default class Map extends Vue {
  map: __esri.Map;
  mapView: __esri.MapView;
  geometryEngine: __esri.geometryEngine;

  private legend: __esri.Expand = null;
  private drawTool: any;
  private measureTool: Measure;
  private onCompleted: OnDrawingComplete;


  lockMapZoom: boolean = false;
  expandableComponent: any = null;

  @Watch("lockMapZoom")
  lockMapZoomChanged(n, o) {
    if (n != o) {
      MapHelper.lockMapZoomAndPan = n;
    }
  }

  currentMapOverlay: any = null;

  @Watch("currentMapOverlay")
  currentMapOverlayChanged(n, o) {
    let container = this.$refs.overlayContainer as HTMLElement;
    if (n != o && container) {
      if (container.hasChildNodes())
        for (const key in container.childNodes) {
          if (container.childNodes.hasOwnProperty(key)) {
            const element = container.childNodes[key];
            container.removeChild(element);
          }
        }

      if (n)
        container.appendChild(n);
    }
  }

  @Watch("expandableComponent")
  expandableComponentChanged(n, o) {
    if (n) {

    }
    else {

    }
  }

  get mapCenter() {
    return this.$store.state.userInterface.userGPSPosition;
  }

  mounted() {
    this.InitMap();
  }

  localizeFunction(value) {
    return localizeFunction(value);
  }

  async InitMap() {
    this.geometryEngine = await Esri.Geometry.geometryEngine();
    this.map = await Esri.Map({ basemap: "gray" } as __esri.MapProperties);


    this.mapView = await Esri.Views.MapView({
      map: this.map,
      container: "map",
      constraints: {
        snapToZoom: false,
        minZoom: 8,
        minScale: 8
      },

      zoom: 12,
      center: this.mapCenter
    } as __esri.MapViewProperties);

    this.mapView.when(async () => {

      let ghostLayer = await Esri.Layers.GraphicsLayer({
        id: "editingLayer",
        listMode: 'hide'
      } as __esri.GraphicsLayerProperties)
      this.map.add(ghostLayer);


      MapHelper.setCurrentMap(this);
    });

    this.addTools();

  }

  async addTools() {
    let $this = this;

    Esri.WidgetsFactory.Search(this.mapView, "top-right");
    // Esri.WidgetsFactory.Home(this.mapView, "bottom-right");
    Esri.WidgetsFactory.BasemapGallery(this.mapView, "bottom-right");
    this.measureTool = new Measure(new MeasureParameters(this.mapView));

    var mapButton = document.getElementById("measure-near-me");
    mapButton.style.display = "flex";
    mapButton.addEventListener("click", () => { });
    this.mapView.ui.add("measure-near-me", "top-right");

    let layerlist = await Esri.WidgetsFactory.LayerList(this.mapView, "top-right", true);
    // Trigger custom actions.
    layerlist.on("trigger-action", function (event) {
      if (event.action.action)
        try { event.action.action() } catch { }
    });

    mapButton = document.getElementById("measure-area-tool");
    mapButton.style.display = "flex";
    mapButton.addEventListener("click", () => { $this.measureTool.MeasureArea(); });
    this.mapView.ui.add("measure-area-tool", "top-right");

    mapButton = document.getElementById("measure-distance-tool");
    mapButton.style.display = "flex";
    mapButton.addEventListener("click", () => { $this.measureTool.MeasurePerimeter(); });
    this.mapView.ui.add("measure-distance-tool", "top-right");


    await this.initDrawTool();
  }

  private DrawingLayer: __esri.GraphicsLayer = null;

  private async initDrawTool() {
    let $this = this;

    this.DrawingLayer = await Esri.Layers.GraphicsLayer({
      listMode: 'hide'
    });
    MapHelper.addLayer(this.DrawingLayer);

    if (!this.drawTool) {
      var mapButton = document.getElementById("draw-polygon");
      mapButton.style.display = "flex";
      // let featureLayer = await MapHelper.GetLayerById(FIELDS_LAYER);

      this.drawTool = await Esri.Widgets.Sketch({
        // activeTool: "polygon",
        layer: this.DrawingLayer,
        view: this.mapView
      });

      this.drawTool.viewModel.polygonSymbol = {
        type: "simple-fill", // autocasts as SimpleFillSymbol
        color: [140, 140, 140, 0.7],
        style: "solid",
        outline: {
          // autocasts as SimpleLineSymbol
          color: [36, 146, 170],
          width: 2,
          cap: "round",
          join: "round"
        }
      };

      this.drawTool.viewModel.on(["create", "update", "redo", "undo"], (event) => {
        let g: __esri.Graphic = null;
        if (event.graphic) g = event.graphic;
        if (event.graphics && event.graphics.length) g = event.graphics[0];

        if (this.onCompleted && event.state == "complete") {
          this.onCompleted(g);
          this.resetDraw();
        }

        // Controllo se le geometrie si possono intersecare
        if (event.toolEventInfo) {
          switch (event.toolEventInfo.type) {
            case "vertex-add":
            case "reshape-stop":
              if (isSelfIntersecting(g.geometry as __esri.Polygon)) {
                event.target.undo();
              }
              break;
          }

          console.log("drawing:toolEventInfo", event.toolEventInfo.type);
        }
      })

      this.drawTool.viewModel.defaultUpdateOptions.enableRotation = false;
      this.drawTool.viewModel.defaultUpdateOptions.enableScaling = false;

      // this.drawTool.viewModel.updateOnGraphicClickBoolean = false;

      mapButton.addEventListener("click", () => {
        this.resetDraw();
        this.drawTool.viewModel.create("polygon", { mode: "hybrid" });
      });
      this.mapView.ui.add("draw-polygon", "top-right");

      this.disableDraw();
    }


    function isSelfIntersecting(geometry: __esri.Polygon) {
      if (geometry.rings[0].length < 3) return false;
      if (geometry.isSelfIntersecting) return true;
      if (geometry.rings.length > 1) return true;
      return false;
    }


  }

  public async addDrawTool(onCompleted: OnDrawingComplete) {
    this.onCompleted = onCompleted;
    var mapButton = document.getElementById("draw-polygon");
    mapButton.style.display = "flex";
    this.DrawingLayer.visible = true; // rengo il drawing layer visibile
    this.drawTool.viewModel._layer.visible = true; // abilito a mano il layer nascosto dello sketch ViewModel
  }

  public async enableDraw(graphic: __esri.Graphic, onCompleted: OnDrawingComplete) {

    this.resetDraw();

    if (onCompleted)
      this.onCompleted = onCompleted;

    let wmu = await Esri.Geometry.Support.webMercatorUtils();

    if (this.drawTool) {
      this.DrawingLayer.visible = true; // rengo il drawing layer visibile
      this.drawTool.viewModel._layer.visible = true; // abilito a mano il layer nascosto dello sketch ViewModel

      if (graphic) {

        if (!graphic.geometry) return;

        if (!graphic.geometry.spatialReference || graphic.geometry.spatialReference.isWGS84)
          graphic.geometry = wmu.geographicToWebMercator(graphic.geometry);

        // preparo il watcher per monitorare le modifiche al layer
        var watcher = (await (await MapHelper.getCurrentMap()).mapView.whenLayerView(this.DrawingLayer)).watch("updating", (val) => {
          if (!val) {
            this.drawTool.viewModel.update([graphic], { tool: "reshape" });
            watcher.remove();
          }
        });

        this.DrawingLayer.graphics.add(graphic);
        return;
      }
      else
        this.drawTool.viewModel.create("polygon", { mode: "hybrid" });
    }
  }

  public resetDraw() {
    if (this.drawTool
      && this.drawTool.viewModel
      && this.drawTool.viewModel._layer
      && this.drawTool.viewModel._layer.graphics
      && this.drawTool.viewModel._layer.graphics.length > 0) {
      try {
        this.DrawingLayer.graphics.splice(0, this.DrawingLayer.graphics.length);
        this.drawTool.viewModel._layer.graphics.splice(0, this.drawTool.viewModel._layer.graphics.length);
        this.drawTool.viewModel.view.graphics.splice(0, this.drawTool.viewModel.view.graphics.length);
      } catch (err) {
        console.debug(err);
      }
    }
  }

  public disableDraw() {
    //nascondo il widget in mappa
    var mapButton = document.getElementById("draw-polygon");
    mapButton.style.display = "none";
    if (this.drawTool) {
      try {
        this.resetDraw();
        if (this.drawTool
          && this.drawTool.viewModel
          && this.drawTool.viewModel._layer
          && this.drawTool.viewModel._layer.graphics
          && this.drawTool.viewModel._layer.graphics.length > 0) {
          this.DrawingLayer.visible = false;
          this.drawTool.viewModel._layer.visible = false;
          this.drawTool.viewModel.reset();
        }
      } catch (err) {
        console.debug(err);
      }
    }
  }

  public finishDraw() {
    if (this.drawTool && this.onCompleted) {
      this.drawTool.complete();
    }
  }
}
