import { baseRestService } from "@/services/_base/baseRestService";
import Config from "@/config"
import moment from "moment";

class ExportService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  /**
   * exportStationData
   */
  public async exportStationData(stationId: string, fromDate: Date, toDate: Date) {
    const data = {
      stationId,
      fromDate,
      toDate,
    }

    window.open(this.baseUrl + `/meteo/measures/export?stationId=${stationId}&fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`);
  }

}


export const exportService = new ExportService();
