import { baseRestService } from "@/services/_base/baseRestService";
import Config from "@/config"
import { suggestionsStoreActions } from "@/store/suggestions.store";

class SuggestionsService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  public async List(arealId?: string, productionChainId?: string, archived?: boolean, skip?: number, take?: number): Promise<server.suggestion[]> {
    var result = await this.Get<server.suggestion[]>("/meteo/suggestions", { skip: skip ?? 0, take: take, archived: archived, arealId: arealId, productionChainId: productionChainId });
    suggestionsStoreActions.setSuggestions(result);
    return result;
  }

  public async GetSuggestion(suggestionId: string): Promise<server.suggestion> {
    var result = await this.Get<server.suggestion>(`/meteo/suggestions/${suggestionId}`);
    suggestionsStoreActions.setSuggestion(result);
    return result;
  }

  public async SaveNew(suggestion: server.suggestion): Promise<server.suggestion> {
    var result = await this.Post<server.suggestion>("/meteo/suggestions", suggestion);
    suggestionsStoreActions.setSuggestion(result);
    return result;
  }

  public async SetData(suggestionId: string, suggestion: server.suggestion): Promise<server.suggestion> {
    var result = await this.Put<server.suggestion>(`/meteo/suggestions`, suggestion);
    suggestionsStoreActions.setSuggestion(result);
    return result;
  }

  public async DeleteSuggestion(suggestionId: string): Promise<any> {
    var result = await this.Delete<any>(`/meteo/suggestions/${suggestionId}`);
    suggestionsStoreActions.removeSuggestion(suggestionId);
    return result;
  }
}
export const suggestionsService = new SuggestionsService();
