import Vue from "vue";
import navItem from "./SidebarItem.vue";
import navItemContainer from "./SidebarItemContainer.vue";
import Component from "vue-class-component";
import { localizeFunction } from "@/filters/localize";
import { sharedInterceptors } from "@/services/_base/baseRestService";
import { usersStoreGetters } from "@/store";
import { mailingListService } from "@/services/mailingListService";

declare var DEVELOPMENT;
declare var STAGING;

@Component({
  components: {
    navItem,
    navItemContainer,
  }
})
export default class sidebar extends Vue {

  get weatherStationsCount() {
    return this.$store.state.weather.stations.length;
  }

  get isProduction() {
    return !(DEVELOPMENT || STAGING);
  }

  checkRoute(routes) {
    return routes.indexOf(this.$route.name) >= 0;
  }

  localize(value: string) {
    return localizeFunction(value)
  }

  userIsInRole(role: string) {
    return usersStoreGetters.userIsInRole(role);
  }

}
