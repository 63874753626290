import { baseRestService } from "@/services/_base/baseRestService";
import Config from "@/config"

class MediaService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  public async UploadImage(type: server.mediaTypes, typeId: string, blob: Blob): Promise<string> {
    var data: server.mediaUpload = {
      data: null,
      format: blob.type,
      id: null,
      type,
      typeId
    }

    // Leggo il contenuto dell'immagine
    await new Promise((res, rej) => {
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        // La stringa che mi resituisce è del tipo "data:base64,ASsd87dfgh9y..."
        //  quindi devo prendere la parte dopo la virgola
        data.data = (reader.result as string).split(",")[1];
        res();
      };
    });

    const result = await this.Post<server.media>("/media/images", data);

    return this.GetImageUrl(result);
  }

  public GetImageUrl(media: server.media): string {
    return this.baseUrl + `/media/images/${media.fileName}`;
  }

}

export const mediaService = new MediaService();
