import { securityService, weatherStationService, localizationService, AlertHelper, rolesService, usersService } from "@/services";
import { geolocalizationService } from "@/services/geolocalizationService";
import { } from "@/services/_base/AlertHelper";
import { resolverConfiguration } from "@/store/UTILS";
import { arealsService } from "@/services/arealService";
import { productionChainsService } from "@/services/productionChainService";

export const ServiceInizialization = async function () {
  if (await securityService.isUserAuthenticated()) {
    if (window.location.search.indexOf("?agrofId=") >= 0) {
      var id = window.location.search.split("=")[1];
      window.localStorage.setItem("agrofId", id);
    }

    try {
      await localizationService.handshake();
      await usersService.Check();
    } catch (err) {
      securityService.Logout();
      return;
    }

    AlertHelper.showLoader("loading");


    await geolocalizationService.getCurrentPosition();
    await Promise.all([
      weatherStationService.ListSensorsTypes(),
      weatherStationService.ListStationsTypes(),
      rolesService.List(),
      arealsService.List(),
      productionChainsService.List()
    ]);

    // first loading completed
    resolverConfiguration.setFirstLoadingCompleted();

    AlertHelper.hideLoader();
  }
};

