const Dashboard = () => import( /* webpackChunkName: "dashboard" */ "./components/Dashboard.vue");


export const route = [
  {
    path: 'dashboard',
    name: 'dashboard',
    component: Dashboard
  }
];
