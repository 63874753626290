import Config from "@/config";
import Qs from "qs";
import { baseRestService, AuthToken } from "@/services/_base/baseRestService";
import { Deferred } from "@/services/_base/Deferred";

/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
class SecurityService extends baseRestService {

  config: any = null;

  private static _myself = null;
  constructor() {
    super();

    this.saveToSessionStorage = false;
    SecurityService._myself = this;
    window.setInterval(this.autoRenewToken, Config.authorizationConfig.checkSessionInterval)
  }


  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  getUserRequestedPath() {
    return window.sessionStorage.getItem("requestedpath");
  }


  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  async startSignIn() {
    window.sessionStorage.setItem("requestedpath", window.location.origin);

    var params = Qs.stringify(
      {
        client_id: Config.authorizationConfig.client_id,
        redirect_uri: Config.clientSideUrl + Config.signinCallbackRoute,
        response_type: "id_token token",
        scope: Config.authorizationConfig.openid_scope,
        nonce: new Date().getTime()
      }
    );

    window.location.href = Config.authorizationServerUrl + "/connect/authorize?" + params;
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  async Login(username: string, password: string): Promise<boolean> {
    var formdata = Qs.stringify({
      grant_type: "password",
      username: username,
      password: password,
      scope: Config.authorizationConfig.openid_scope
    });

    var result = await this.http.post(this.baseUrl + "/connect/token", formdata, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

    if (result.status == 200) {
      if (result.data.expires_in) {
        result.data.expiration_date = new Date(new Date().getTime() + result.data.expires_in * 1000).getTime();
      }
      this.setAuthenticationToken(result.data as AuthToken);
      return true;
    } else {
      this.deleteAuthenticationToken();
      if (this.OnError != null)
        this.OnError(result);
    }
    return false;
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  async Logout() {
    this.deleteAuthenticationToken();
    window.localStorage.removeItem("userInfo");
    window.localStorage.removeItem("agrofId");
    window.localStorage.removeItem("fieldPresets");
    await this.remotelogoff();
    this.startSignIn();
    // window.location.href = Config.authorizationServerUrl + "/Account/Logoff?returnurl=" + Config.clientSideUrl;
  }


  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  public getUserOrganizationId(): string {
    var agrofId = window.localStorage.getItem("agrofId");

    if (agrofId)
      return agrofId;

    var user = JSON.parse(window.localStorage.getItem("userInfo")) as UserInfo;

    if (user)
      return user.organization_id || "1";
    return "1";
  }


  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  public getUserId(): number {
    var user = JSON.parse(window.localStorage.getItem("userInfo")) as UserInfo;
    return user == null ? null : user.sub;
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  public getUsername(): string {
    var user = JSON.parse(window.localStorage.getItem("userInfo")) as UserInfo;
    return user == null ? null : user.email;
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  async getUserInfo() {
    try {
      var resultInfo = await this.get(Config.authorizationServerUrl + "/api/userinfo");

      window.localStorage.setItem("userInfo", JSON.stringify(resultInfo.data));
    } catch (err) {
      this.Logout();
    }
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  public isUserAuthenticated() {
    return window.localStorage.getItem("userInfo") != null;
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  async autoRenewToken() {
    var token = SecurityService._myself.getAuthenticationToken();

    if (token) {
      var now = new Date().getTime();
      var timeleft = token.expiration_date - now;

      SecurityService._myself.checkAuthenticationResponse();
      SecurityService._myself.getUserInfo();
      if (timeleft < Config.authorizationConfig.renewSessionTimeLimit * 1000) {
        await SecurityService._myself.renewToken(token);
      }
    }
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  setUserAuth(data: any) {
    if (data.expires_in) {
      data.expiration_date = new Date(new Date().getTime() + data.expires_in * 1000).getTime();
    }
    this.setAuthenticationToken(data);
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  checkAuthenticationResponse() {
    var auth = window.sessionStorage.getItem("authresponse");
    window.sessionStorage.removeItem("authresponse");

    if (auth) {
      var authobj = Qs.parse(auth.substr(1));
      securityService.setUserAuth(authobj);
    }
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  private async renewTokenUsingRefreshToken(token: AuthToken) {
    // flow using refresh_token
    var formdata = Qs.stringify({
      grant_type: "refresh_token",
      refresh_token: token.refresh_token || token.id_token,
      scope: Config.authorizationConfig.openid_scope
    });

    var result = await SecurityService._myself.http.post(SecurityService._myself.baseUrl + "/connect/token", formdata, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
    if (result.status == 200) {
      if (result.data.expires_in) {
        result.data.expiration_date = new Date(new Date().getTime() + result.data.expires_in * 1000).getTime();
      }

      if (!result.data.refresh_token)
        result.data.refresh_token = token.refresh_token;
      SecurityService._myself.setAuthenticationToken(result.data as AuthToken);
    }
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  private async remotelogoff() {
    let waitDeferred = new Deferred();
    let element = document.createElement("iframe");
    element.id = "__logoff__frame";
    element.style.display = "none";
    document.body.appendChild(element);

    element.addEventListener("load", () => {
      waitDeferred.resolve();
      document.body.removeChild(element);
    })
    element.src = Config.authorizationServerUrl + "/connect/logout";
    await waitDeferred.promise;
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  private async renewTokenUsingSilentLogin(token: AuthToken) {
    var params = Qs.stringify(
      {
        client_id: Config.authorizationConfig.client_id,
        redirect_uri: Config.clientSideUrl + Config.signinCallbackRoute,
        response_type: "id_token token",
        scope: Config.authorizationConfig.openid_scope,
        nonce: new Date().getTime(),
        prompt: "none"
      }
    );

    let element: HTMLIFrameElement = document.getElementById("__login__frame") as HTMLIFrameElement;
    if (!element) {
      element = document.createElement("iframe");
      element.id = "__login__frame";
      element.style.display = "none";
      document.body.appendChild(element);
    }
    element.src = Config.authorizationServerUrl + "/connect/authorize?" + params;
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  private async renewToken(token: AuthToken) {
    if (token.refresh_token) await this.renewTokenUsingRefreshToken(token);
    else await this.renewTokenUsingSilentLogin(token);
  }
}

export const securityService = new SecurityService();

/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
export interface UserInfo {
  sub: number;
  email: string;
  email_verified: boolean;
  organization_id: string;
}
