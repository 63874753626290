
import { baseRestService } from "@/services/_base/baseRestService";
import Config from "@/config"
import { usersStoreActions } from "@/store";

class ArealsUsersServices extends baseRestService {
  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  public async List(userId: any): Promise<server.arealUserDetail[]> {
    var result = await this.Get<server.arealUserDetail[]>(`/users/${userId}/areals`);
    if (result) usersStoreActions.setUserAreals(result);
    return result;
  }

  public async Set(userId: string, arealId: string): Promise<server.arealUserDetail> {
    let result = await this.Post<server.arealUserDetail>(`/users/${userId}/areals`, { userId, arealId } as server.arealUserDetail);
    if (result) usersStoreActions.setUserAreals([result]);
    return result;
  }

  public async Remove(userId: string, arealId: string): Promise<boolean> {
    let obj = { userId, arealId };
    let result = await this.Delete<boolean>(`/users/${userId}/areals/${arealId}`, obj);
    if (result) usersStoreActions.removeUserAreals(obj);
    return result;
  }
}
export const arealsUsersServices = new ArealsUsersServices();
