import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'
import { securityService } from '@/services/securityService'


import { Component } from 'vue-property-decorator'

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteUpdate',
    'beforeRouteLeave'
]);

Vue.use(VueRouter);

let router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),
    routes
})

router.beforeEach(async(to, from, next) => {
    //if the route requires authentication and the user is not authenticated, start the sign in process
    var isUserAuthenticated = await securityService.isUserAuthenticated();
    if (to.matched.some(record => record.meta.requireAuth) && !isUserAuthenticated) {
        next(false);
        await securityService.startSignIn(to);
    } else {
        next();
    }
})

export default router