export default {
  methods:{


  doOnce: function(callback, timeout){
    if(this._$timeout$_)
    {
      clearTimeout(this._$timeout$_);
      this._$timeout$_ = null;
    }

    this._$timeout$_ = setTimeout(callback, timeout);
  }}
}
