<template>
  <div class="card-dashboard card-dashboard-button" @click="onClick">
    <header>
      <i :class="iconClass"></i>&nbsp;{{title_button}}
    </header>
    <div class="body">
      <p class="description">{{firstDescription}}</p>
      <p class="sub-description">{{secondDescription}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title_button: { default: "" },
    iconClass: { default: "" },
    firstDescription: { default: "" },
    secondDescription: { default: "" },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>
