import { baseRestService } from "./_base/baseRestService";
import Config from "@/config";
import { productionChainsStoreActions } from "@/store";

class ProductionChainsService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  public async List(skip: number = 0, take: number = 1000): Promise<server.productionChain[]> {
    var result = await this.Get<server.productionChain[]>("/production-chain", { skip, take });
    productionChainsStoreActions.setProductionChains(result)
    return result;
  }

 public async Save(productionChain: server.productionChain): Promise<server.productionChain> {
    let result = await this.Post<server.productionChain>("/production-chain", productionChain);
    productionChainsStoreActions.setProductionChains([result])
    return result;
  }

  public async Remove(id: string): Promise<any> {
    var result = await this.Delete<any>(`/production-chain/${id}`);
    productionChainsStoreActions.removeProductionChain(id);
    return result;
  }
}
export const productionChainsService = new ProductionChainsService();
