import { baseRestService } from "@/services/_base/baseRestService";
import Config from "@/config"
import { weatherStoreActions, store, stationStoreActions } from "@/store";
import moment from "moment";

class WeatherForecastService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = Config.appServerCDNUrl;
  }

  async Forecasts(latitude: number, longitude: number, date: Date = null, count: number = null) {
    let params = {
      latitude: latitude,
      longitude: longitude,
    } as any;

    if (date) params.date = date;
    if (count) params.count = count;

    return await this.Get<any>("/meteo/weather/forecast", params);
  }

  async DailyForecasts(latitude: number, longitude: number, date: Date = null, count: number = null) {
    let params = {
      latitude: latitude,
      longitude: longitude,
    } as any;

    if (date) params.date = date;
    if (count) params.count = count;

    return await this.Get<server.dailyMeasureDetail[]>("/meteo/weather/forecast/daily", params);
  }

  async ForecastForStation(latitude: number, longitude: number, stationid: string) {

    // Evito di fare chiamate al millisecondo, è inutile in questo caso,
    // Va bene una chiamata che tiene conto dell'ultima ora.
    // let minutes = Math.round(moment().minutes() / 10) * 10;
    let date = moment().minutes(0).seconds(0).milliseconds(0).toDate();
    let result = await this.Forecasts(latitude, longitude, date, 1);
    weatherStoreActions.setStationForecast({ stationId: stationid, forecasts: result });
    return result;
  }

  async ChartForStation(latitude: number, longitude: number, stationId: string) {
    // Evito di fare chiamate al millisecondo, è inutile in questo caso,
    // Va bene una chiamata che tiene conto dell'ultima ora.
    // let minutes = Math.round(moment().minutes() / 10) * 10;
    // let date = moment().startOf('day').toDate();
    let result = await this.Chart(latitude, longitude, null);
    return result;
  }

  async Chart(latitude: number, longitude: number, date?: Date) {
    let params = {
      latitude,
      longitude,
      date
    }
    return await this.Get<any>("/meteo/weather/chart", params);
  }

  async Current(latitude: number, longitude: number, ws: number) {
    let params = {
      latitude: latitude,
      longitude: longitude,
      ws: ws
    }
    return await this.Get<any>("/meteo/weather/current", params);
  }
}

export const weatherForecastService = new WeatherForecastService();
