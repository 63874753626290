
import { baseRestService } from "@/services/_base/baseRestService";
import Config from "@/config"
import { usersStoreActions, rolesStoreActions } from "@/store";

class UsersService extends baseRestService {
  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  async Count(username?: string): Promise<number> {
    return await this.Get<number>(`/users/count`, { username: username });
  }

  public async List(): Promise<server.userDetail[]> {
    var result = await this.Get<server.userDetail[]>("/users");
    if (result) usersStoreActions.setUsers(result);
    return result;
  }

  public async Check(): Promise<server.userDetail> {
    var result = await this.Get<server.userDetail>(`/users/check-exist`);
    if (result) {
      usersStoreActions.setCurrentUser(result);
      usersStoreActions.setUsers([result]);
    };
    return result;
  }

  public async InviteUser(model): Promise<server.userDetail> {
    let result = await this.Post<server.userDetail>(`/users/invite`, model);
    if (result) usersStoreActions.setUsers([result]);
    return result;
  }

  public async Manage(user: server.userDetail): Promise<server.userDetail> {
    var result = await this.Put<server.userDetail>(`/users/${user.id}`, user);
    if (result) usersStoreActions.setUsers([result]);
    return result;
  }

  public async Remove(userId: string): Promise<any> {
    var result = await this.Delete<any>(`/users/${userId}`);
    if (result) usersStoreActions.removeUser(userId);
    return result;
  }

  public async Roles(userId: any): Promise<server.userRoleDetail[]> {
    var result = await this.Get<server.userRoleDetail[]>(`/users/${userId}/roles`);
    if (result) usersStoreActions.setUserRoles(result);
    return result;
  }

  public async SetRole(userId: string, roleId: string): Promise<server.userRoleDetail> {
    let result = await this.Post<server.userRoleDetail>(`/users/${userId}/roles`, { userId, roleId } as server.userRoleDetail);
    if (result) usersStoreActions.setUserRoles([result]);
    return result;
  }

  public async RemoveRole(userId: string, roleId: string): Promise<boolean> {
    let obj = { userId, roleId };
    let result = await this.Delete<boolean>(`/users/${userId}/roles/${roleId}`, obj);
    if (result) usersStoreActions.removeUserRoles(obj);
    return result;
  }
}
export const usersService = new UsersService();
