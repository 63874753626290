import { propertyResolve } from "@/store";
import { usersService } from "@/services";
import { usersStoreGetters } from "../users.store";
import { arealsUsersServices } from "@/services/arealsUsersServices";
import { productionChainsUsersServices } from "@/services/productionChainsUsersServices";

export class UserRolesIntersection implements IUserDetail {
  id: any;
  userName: string;
  firstName: string;
  lastName: string;
  description: string;

  get roles(): server.roleDetail[] {
    return propertyResolve(usersStoreGetters.getUserRoles(this.id), () => usersService.Roles(this.id), this.id);
  }

  get areals(): server.areal[] {
    return propertyResolve(usersStoreGetters.getUserAreals(this.id), () => arealsUsersServices.List(this.id), this.id);
  }

  get productionChains(): server.productionChain[] {
    return propertyResolve(usersStoreGetters.getUserProductionChains(this.id), () => productionChainsUsersServices.List(this.id), this.id);
  }
}

export const userRolesIntersection = new UserRolesIntersection();
