import { fillArray } from "@/store";
import { CreateActionsHandler, CreateGettersHandler } from "./UTILS";


export interface StationStoreModel {
  stationsHW: server.station[];
  stationTypes: server.stationType[];
  dataProviders: server.stationProvider[];
  sensors: server.stationSensor[];
  sensorTypes: server.stationSensorType[];
  sensorTemplates: server.stationSensorTemplate[];
}

// Actions Interface
interface StationStoreActions {
  setStationHW: (station: server.station) => any
  // setStations: (stations: server.station[]) => any
  // setStationType: (stationType: server.stationType) => any
  setStationTypes: (stationTypes: server.stationType[]) => any
  setDataProvider: (dataProviders: server.stationProvider) => any
  setDataProviders: (dataProviders: server.stationProvider[]) => any
  setSensor: (sensor: server.stationSensor) => any
  setSensors: (sensors: server.stationSensor[]) => any
  // setSensorType: (sensorTypes: server.stationSensorType) => any
  setSensorTypes: (sensorTypes: server.stationSensorType[]) => any
  // setSensorTemplate: (sensorTypes: server.stationSensorTemplate) => any
  setSensorTemplates: (sensorTypes: server.stationSensorTemplate[]) => any
  removeStationHW: (stationId: string) => any
  // removeDataProvider: (stationId: string) => any
  removeSensor: (sensorId: string) => any
  // removeSensorType: (sensorTypeId: string) => any
  // removeSensorTemplate: (sensorTemplateId: string) => any
}

// Getters Interface
interface StationStoreGetters {

  getSensorsByTypes: (ids: number[]) => server.stationSensorType[]
  getStationsByTypes: (ids: number) => server.stationType
  getStation: (id: string) => server.station
  getSensors: (idStation: string) => server.stationSensor[]
}



export const stationStore = {
  PREFIX: "weatherStations",
  namespaced: true,
  state: {
    _stationsHW: new Map<string, server.station>(),
    stationsHW: [],
    _stationTypes: new Map<number, server.stationType>(),
    stationTypes: [],
    _dataProviders: new Map<number, server.stationProvider>(),
    dataProviders: [],
    _sensors: new Map<number, server.stationSensor>(),
    sensors: [],
    _sensorTypes: new Map<number, server.stationSensorType>(),
    sensorTypes: [],
    _sensorTemplates: new Map<number, server.stationSensorTemplate>(),
    sensorTemplates: [],
  },
  getters: {
    getSensorsByTypes: (state) => (ids): server.stationSensorType[] => state.sensorTypes.filter((f) => ids.indexOf(f.id) >= 0),
    getStationsByTypes: (state) => (ids): server.stationType => state.stationTypes.find((f) => ids == f.id),
    getStation: (state) => (id): server.station => state._stationsHW[id],
    getSensors: (state: StationStoreModel) => (idStation: string): server.stationSensor[] => state.sensors.filter((f) => f.idStation == idStation),
  },
  mutations: {
    setStationHW(state, station: server.station) {
      state._stationsHW.set(station.id, station);
      fillArray(state.stationsHW, state._stationsHW.values());
    },

    setStationTypes(state, stationTypes: server.stationType[]) {
      for (const key in stationTypes) {
        const stationType = stationTypes[key];
        state._stationTypes.set(stationType.id, Object.freeze(stationType));
      }
      fillArray(state.stationTypes, state._stationTypes.values());
    },
    setDataProvider(state, dataProvider: server.stationProvider) {
      state._dataProviders.set(dataProvider.id, dataProvider);
      fillArray(state.dataProviders, state._dataProviders.values());
    },
    setDataProviders(state, dataProviders: server.stationProvider[]) {
      for (const key in dataProviders) {
        const dataProvider = dataProviders[key];
        state._dataProviders.set(dataProvider.id, dataProvider);
      }
      fillArray(state.dataProviders, state._dataProviders.values());
    },
    setSensor(state, sensor: server.stationSensor) {
      state._sensors.set(sensor.id, sensor);
      fillArray(state.sensors, state._sensors.values());
    },
    setSensors(state, sensors: server.stationSensor[]) {
      for (const key in sensors) {
        const sensor = sensors[key];
        state._sensors.set(sensor.id, sensor);
      }
      fillArray(state.sensors, state._sensors.values());
    },

    setSensorTypes(state, sensorTypes: server.stationSensorType[]) {
      for (const key in sensorTypes) {
        const sensorType = sensorTypes[key];
        state._sensorTypes.set(sensorType.id, Object.freeze(sensorType));
      }
      fillArray(state.sensorTypes, state._sensorTypes.values());
    },
    setSensorTemplate(state, sensorTemplate: server.stationSensorTemplate[]) {
      for (const key in sensorTemplate) {
        const sensorType = sensorTemplate[key];
        state._sensorTemplates.set(sensorType.template, Object.freeze(sensorType));
      }
      fillArray(state.sensorTemplates, state._sensorTemplates.values());
    },

    removeStationHW(state, stationId: string) {
      state._stationsHW.delete(stationId);
      fillArray(state.stationsHW, state._stationsHW.values());
    },
    removeStationType(state, stationTypeId: number) {
      state._stationTypes.delete(stationTypeId);
      fillArray(state.stationTypes, state._stationTypes.values());
    },

    removeSensor(state, sensorId: string) {
      state._sensors.delete(sensorId);
      fillArray(state.sensors, state._sensors.values());
    },

  },
  actions: {
    setStationHW(context, station: server.station) {
      context.commit("setStationHW", station);
    },

    setStationTypes(context, stationTypes: server.stationType[]) {
      context.commit("setStationTypes", stationTypes);
    },
    setDataProvider(context, dataProvider: server.stationProvider) {
      context.commit("setDataProvider", dataProvider);
    },
    setDataProviders(context, dataProviders: server.stationProvider[]) {
      context.commit("setDataProviders", dataProviders);
    },
    setSensor(context, sensor: server.stationSensor) {
      context.commit("setSensor", sensor);
    },
    setSensors(context, sensors: server.stationSensor[]) {
      context.commit("setSensors", sensors);
    },

    setSensorTypes(context, sensorTypes: server.stationSensorType[]) {
      context.commit("setSensorTypes", sensorTypes);
    },
    setSensorTemplates(context, sensorTemplate: server.stationSensorTemplate[]) {
      context.commit("setSensorTemplate", sensorTemplate);
    },
    removeStationHW(context, stationId: string) {
      context.commit("removeStationHW", stationId);
    },
    removeStationType(context, stationTypeId: string) {
      context.commit("removeStationType", stationTypeId);
    },

    removeSensor(context, sensorId: string) {
      context.commit("removeSensor", sensorId);
    },

  },
};


export const stationStoreActions = CreateActionsHandler<StationStoreActions>(stationStore);
export const stationStoreGetters = CreateGettersHandler<StationStoreGetters>(stationStore);
