import { CreateActionsHandler, CreateGettersHandler } from "./UTILS";
import Vue from "vue";

export interface UiStoreModel {
  userGPSPosition: Number[],
  loading: boolean,
  currentDate: Date,
}

interface UiStoreActions {
  showIntervalFilter: (visibility: boolean) => any;
  toggleDetailFilter: () => any;
  toggleGroups: () => any;
  closeFilterPanel: () => any;
  closeDetailFilter: () => any;
  clearFilter: () => any;
  setFilter: (filter) => any;
  setTextFilter: (filter) => any;
  updateUserPosition: (data: Number[]) => any;
  loadCompleted: () => any;
  toggleDetailFAQ: () => any;
  closeDetailFAQ: () => any;
  addGroupsDefinition: (groups: IGroupDefinition[]) => any;
  clearGroupsDefinitions: () => any;
  setFieldFilter: (fieldId) => any;
}

interface UiStoreGetters {
  getGroupDefinitionByName: (gname) => IGroupDefinition,
  getGroupDefinitionsNumber: () => number;
}

export const uiStore = {
  PREFIX: "userInterface",
  namespaced: true,
  state: {
    userGPSPosition: [12.833333, 42.833333],
    loading: true,
    showDetailedFilter: false,
    showGroups: false,
    showIntervalFilter: true,
    showDetailedFAQ: false,
    excludeSuspectData: false,
    currentDate: new Date(),
    groupsDefinition: {},
    currentUIFilter: {
      text: "",
      byFarmCenter: null,
      byField: null,
      byCrop: null,
      byVarieties: null,
      from: null,
      to: null,
      interval: null,
      extended: null,
    },
    currentGroup: {
      groupBy: "farmCenterId",
      thenBy: "cropId"
    }
  },
  getters: {
    getGroupDefinitionByName: (state) => (gname): IGroupDefinition => state.groupsDefinition[gname],
    getGroupDefinitionsNumber: (state) => (): number => {
      let groupingsNames = Object.getOwnPropertyNames(state.groupsDefinition);
      groupingsNames.splice(groupingsNames.indexOf("__ob__"), 1);
      return groupingsNames.length;
    }
  },
  mutations: {
    addGroupsDefinition(state, groups: IGroupDefinition[]) {
      groups.forEach(g => Vue.set(state.groupsDefinition, g.fieldName, g));
      // groups.forEach(g => state.groupsDefinition.push(g));
    },
    clearGroupsDefinitions(state) {
      // state.currentGroup.groupBy = null;
      // state.currentGroup.thenBy = null;
      //state.groupsDefinition.splice(0, state.groupsDefinition.length);
      state.groupsDefinition = {}
    },
    updateStoreUI(state, dataObject) {
      for (const key in dataObject) {
        if (state.hasOwnProperty(key)) {
          state[key] = dataObject[key];
        }
      }
    },
    setTextFilter(state, filter) {
      state.currentUIFilter.text = filter;
    },
    toggleDetailFilter(state) {
      state.showDetailedFilter = !state.showDetailedFilter;
      if (state.showDetailedFilter) state.showGroups = false;
    },
    toggleGroups(state) {
      state.showGroups = !state.showGroups;
      if (state.showGroups) state.showDetailedFilter = false;
    },
    closeFilterPanel(state) {
      state.showGroups = false;
      state.showDetailedFilter = false;
    },
    showIntervalFilter(state, visibility: boolean) {
      state.showIntervalFilter = visibility;
      if (visibility == false) {
        state.currentUIFilter.interval = 0;
        state.currentUIFilter.from = null;
        state.currentUIFilter.to = null;
      }
    },
    closeDetailFilter(state) {
      state.showDetailedFilter = false;
    },
    toggleDetailFAQ(state) {
      state.showDetailedFAQ = !state.showDetailedFAQ;
    },
    closeDetailFAQ(state) {
      state.showDetailedFAQ = false;
    },
    setFieldFilter(state, fieldId) {
      state.currentUIFilter.byField = fieldId
    }
  },
  actions: {
    addGroupsDefinition(context, groups: IGroupDefinition[]) {
      context.commit("addGroupsDefinition", groups);
    },
    clearGroupsDefinitions(context) {
      context.commit("clearGroupsDefinitions");
    },
    toggleDetailFilter(context) {
      context.commit("toggleDetailFilter");
    },
    toggleGroups(context) {
      context.commit("toggleGroups");
    },
    closeFilterPanel(context) {
      context.commit("closeFilterPanel");
    },
    closeDetailFilter(context) {
      context.commit("closeDetailFilter");
    },
    setFilter(context, filter) {
      context.commit("updateStoreUI", { currentUIFilter: filter });
    },
    setTextFilter(context, filter) {
      context.commit("setTextFilter", filter);
    },
    setFieldFilter(context, fieldId) {
      context.commit("setFieldFilter", fieldId);
    },
    clearFilter(context) {
      context.commit("updateStoreUI", {
        currentUIFilter: {
          text: "",
          byFarmCenter: null,
          byField: null,
          byCrop: null,
          byVarieties: null,
          from: null,
          to: null
        },
      })
    },
    showIntervalFilter(context, visibility: boolean) {
      context.commit("showIntervalFilter", visibility);
    },
    updateUserPosition(context, data: Number[]) {
      context.commit("updateStoreUI", { userGPSPosition: data });
    },
    loadCompleted(context) {
      context.commit("updateStoreUI", { loading: false });
    },
    toggleDetailFAQ(context) {
      context.commit("toggleDetailFAQ");
    },
    closeDetailFAQ(context) {
      context.commit("closeDetailFAQ");
    },
  }
}

// export const uiStoreActions = uiStore.actions as UiStoreActions;
export const uiStoreActions = CreateActionsHandler<UiStoreActions>(uiStore);
export const uiStoreGetters = CreateGettersHandler<UiStoreGetters>(uiStore);
export interface IGroupDefinition {
  fieldName: string,
  label: string,
  groupby: (e) => { id: number, name: string };
}
