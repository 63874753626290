import { Esri } from "@/esriMap";
import { Deferred } from "@/services/_base/Deferred";
import { messageService } from "@/services/_base";
import Vue from "vue";
import { ErrorManagementInterceptors } from "@/services/interceptors/errorManagementInterceptors";
import { PointCloudRGBRenderer } from "esri/pointCloudRenderers";

export interface OnDrawingComplete {
  (graphic: __esri.Graphic): void;
}
export interface OnDrawingReset {
  (): void;
}

export interface GraphicSelectFunction {
  (graphic: __esri.Graphic): boolean;
}

export interface OnGraphicClick {
  (graphic: __esri.Graphic): boolean;
}

export interface OnMapClick {
  (event): void;
}
export interface OnMapHitTest {
  (event, results: Array<__esri.Graphic>): void;
}

export class MapHelper {
  public static lockMapZoomAndPan: boolean = false;
  private static CurrentMap: {
    map: __esri.Map,
    mapView: __esri.MapView,
    sketcher: __esri.SketchViewModel,
    enableDraw: Function,
    resetDraw: Function,
    disableDraw: Function,
    addDrawTool: Function,
    finishDraw: Function,
    currentMapOverlay: any
  };
  private static Sketcher: any = null;
  private static mapreadyPromise: Deferred<any> = new Deferred();

  private static layersok: Array<string> = new Array<string>();

  static setCurrentMap(map: any) {
    MapHelper.CurrentMap = map;
    MapHelper.Sketcher = map.sketcher;
    this.mapreadyPromise.resolve();

    // register map events.
    map.mapView.on("click", (e) => {

      let screenPoint = {
        x: e.x,
        y: e.y
      };

      // // Valuto gli Hittest se sono in editing
      // if (MapHelper.Sketcher && MapHelper.editingGraphic)
      // {
      //   map.mapView.hitTest(screenPoint).then((response: __esri.HitTestResult) => {
      //     let results = response.results.map((r) => r.graphic);

      //     // Controllo gli hittest per l'editing
      //     if (results && results.length) MapHelper.Sketcher.update(MapHelper.editingGraphic);
      //   });
      //   return;
      // }


      if (MapHelper.nextClick) {
        MapHelper.nextClick(e);
        MapHelper.nextClick = null;
      }

      if (MapHelper.hitTestNextClick) {
        e.stopPropagation(); //stoppo la propagazione dell'evento click per impedire l'apertura del popup
        map.mapView.popup.visible = false; //nascondo il popup tante le volte fosse visibil
        map.mapView.hitTest(screenPoint).then((response: __esri.HitTestResult) => {
          let results = response.results.map((r) => r.graphic);

          MapHelper.hitTestNextClick(e, results);
          MapHelper.hitTestNextClick = null;
        });
      }


    });

    map.mapView.on("layerview-create", (event) => {
      MapHelper.layersok.push(event.layer.id);
    });

    //devo invocare la funzione che renderizza il contenuto ogni volta che cambio feature altrimenti non viene cambiato il valore della prop al componente vue
    map.mapView.popup.watch("selectedFeature", (newValue, oldValue) => {
      if (newValue && newValue.popupTemplate && newValue.popupTemplate.content instanceof Function)
        newValue.popupTemplate.content();
    });
  }

  static async getCurrentMap() {
    await MapHelper.mapreadyPromise.promise;
    return MapHelper.CurrentMap;
  }

  public static async Redraw() {
    var mw = (await MapHelper.getCurrentMap()).mapView;
    mw.center = [mw.center.longitude, mw.center.latitude] as any;
  }

  static async GetLayerById(id: string): Promise<any> {
    let map = (await this.getCurrentMap()).map;
    return map.allLayers.find((l) => l.id == id);
  }

  static async GetLayerByName(name: string) {
    let map = (await this.getCurrentMap()).map;
    return map.allLayers.find((l: any) => l.name == name);
  }

  static async GetLayerView(id: string): Promise<__esri.LayerView> {
    let layer = await this.GetLayerById(id);
    return (await MapHelper.CurrentMap.mapView.whenLayerView(layer));
  }

  public static async WaitMapReady(): Promise<void> {
    await this.getCurrentMap();
  }

  public static async WaitLayersReady(...names: string[]) {
    for (var k in names) {
      await MapHelper.WaitLayerReady(names[k]);
    }
  }

  public static async WaitLayerReady(name: string): Promise<void> {
    var deferred = new Deferred();

    var mv = (await this.getCurrentMap()).mapView;

    let result = MapHelper.layersok.indexOf(name) >= 0;
    if (result) {
      deferred.resolve();
      return;
    }

    var cancellation = mv.on("layerview-create", (event) => {
      if (event.layer.id == name) deferred.resolve();
    });

    await deferred.promise;
    cancellation.remove();
  }
  public static async enableDrawing(onCompleted: OnDrawingComplete) {
    await (await this.getCurrentMap()).addDrawTool(onCompleted);
  }
  public static async startDrawing(graphic: __esri.Graphic = null, onCompleted: OnDrawingComplete = null) {
    await (await this.getCurrentMap()).enableDraw(graphic, onCompleted);
  }
  public static async resetDrawing() {
    await (await this.getCurrentMap()).resetDraw();
  }
  public static async disableDraw() {
    await (await this.getCurrentMap()).disableDraw();
  }

  public static async finishDraw() {
    await (await this.getCurrentMap()).finishDraw();
  }

  public static async showGraphic(g: __esri.Graphic) {
    (await this.getCurrentMap()).mapView.graphics.add(g);
  }

  public static async removeGraphic(g: __esri.Graphic) {
    (await this.getCurrentMap()).mapView.graphics.remove(g);
  }

  public static async showMapOverlay(component: HTMLElement) {
    if (component) {
      component.remove();
    }

    (await this.getCurrentMap()).currentMapOverlay = component;
  }

  public static async removeMapOverlay() {
    (await this.getCurrentMap()).currentMapOverlay = null;
  }

  private static _grouplayers: Map<string, __esri.GroupLayer> = new Map<string, __esri.GroupLayer>();
  public static async addLayer(layer: __esri.Layer, index: number = null, groupId: string = null, groupName: string = null) {
    if (groupId) {
      if (!MapHelper._grouplayers.has(groupId)) {
        let gl = await Esri.Layers.GroupLayer({ id: groupId, title: groupName || groupId } as __esri.GroupLayerProperties);
        // E' necessario un doppio controllo a causa delle asincronicità di alcune inizializzazioni.
        if (!MapHelper._grouplayers.has(groupId)) MapHelper._grouplayers.set(groupId, gl);
        (await this.getCurrentMap()).map.add(MapHelper._grouplayers.get(groupId));
      }

      MapHelper._grouplayers.get(groupId).add(layer, index);
    }
    else
      (await this.getCurrentMap()).map.add(layer, index);
  }

  public static async removeLayer(layer: __esri.Layer) {
    let map = (await this.getCurrentMap()).map;
    if (MapHelper._grouplayers.has(layer.id)) MapHelper._grouplayers.delete(layer.id);
    map.remove(layer);
  }

  public static async goToGeometry(geometry: __esri.Geometry, zoom: number = 18) {
    if (MapHelper.lockMapZoomAndPan) return;
    if (geometry.extent) (await this.getCurrentMap()).mapView.goTo(geometry.extent.expand(1.2), { animate: false });
    else (await this.getCurrentMap()).mapView.goTo({ target: geometry, zoom: zoom }, { animate: false });
  }

  public static async goToLayer(layername: string, extend: boolean = true) {
    if (MapHelper.lockMapZoomAndPan) return;
    let layer = await MapHelper.GetLayerByName(layername);

    if (layer) {
      let lv = await (await this.getCurrentMap()).mapView.whenLayerView(layer) as any;

      let extent = await Esri.Geometry.ExtentOf(lv.layer.graphics.items.map((g) => g.geometry));
      if (extent) (await this.getCurrentMap()).mapView.goTo(extend ? extent.expand(1.2) : extent, { animate: false });
    }
  }

  public static async highlightsGeometry(geometry: __esri.Geometry, symbol: any, zoomTo: boolean = false) {
    let graphic = await Esri.Graphic({
      geometry: geometry,
      symbol: symbol
    });
    await MapHelper.showGraphic(graphic);
    if (zoomTo) {
      await MapHelper.goToGeometry(geometry);
    }
    return graphic;
  }

  private static nextClick: OnMapClick = null;
  public static async onNextClick(callback: OnMapClick) {
    MapHelper.nextClick = callback;
  }
  private static hitTestNextClick: OnMapHitTest = null;
  public static async onNextHitTest(callback: OnMapHitTest) {
    MapHelper.hitTestNextClick = callback;
  }

  public static addCustomActionToLayer(layer: __esri.Layer, actionid: string, actiontitle: string, actioniconclass: string, action: () => void) {
    let l = layer as any;
    if (!l.customActions) l.customActions = [];
    l.customActions.push({ title: actiontitle, id: l.id + "_" + actionid, className: actioniconclass, action: action });
  }

  public static async ShowLayer(layerid: string) {
    let result = (await this.GetLayerById(layerid)) as __esri.Layer;
    if (result) result.visible = true;
  }

  public static async HideLayer(layerid: string) {
    let result = (await this.GetLayerById(layerid)) as __esri.Layer;
    if (result) result.visible = false;
    if (this.CurrentMap.mapView.popup.visible) {
      this.CurrentMap.mapView.popup.close();
    }
  }

  public static async ShowAllLayersBut(...but: string[]) {
    await this.WaitMapReady();
    let layers = this.CurrentMap.map.allLayers;
    if (layers)
      layers.forEach((layer) => {
        if (but.indexOf(layer.id) >= 0) {
          layer.visible = false;
          return;
        }
        layer.visible = true;
      });
  }

  public static async HideAllLayersBut(...but: string[]) {
    await this.WaitMapReady();
    if (this.CurrentMap.mapView.popup.visible) {
      this.CurrentMap.mapView.popup.close();
    }
    let layers = this.CurrentMap.map.allLayers;

    if (layers)
      layers.forEach((layer) => {
        if (but.indexOf(layer.id) >= 0) {
          layer.visible = true;
          return;
        }

        if (layer.type != "group" && layer.type != "tile")
          layer.visible = false;
      });
  }

  public static async HideMap() {
    messageService.publishToChannel("resize-map", 3);
  }

  public static async ShowMapSmall() {
    messageService.publishToChannel("resize-map", 2);
  }

  public static async ShowMapMedium() {
    messageService.publishToChannel("resize-map", 1);
  }

  public static async ShowMapFull() {
    messageService.publishToChannel("resize-map", 0);
  }

  public static CreatePopupTemplate(component) {
    let comp = new (Vue.extend(component))();
    return comp.$mount();
  }

  public static async SetGraphicPopupTemplate(component: Vue, title: string | Function, contentFunction: OnComponentCreated) {
    let pp = await Esri.PopupTemplate({
      title: title,
      content: () => {
        if (contentFunction) contentFunction(component);
        return component.$el;
      }
    });
    return pp;
  }
}

export interface OnComponentCreated {
  (component: Vue): void;
}

//COLORI DEI SIMBOLI IN MAPPA
export class MapColors {
  //GENERAL
  static genericPoint: string = "#1caadd";
  static genericPointBackground = "rgba(28,170,221,0.30)";
  static circleHighlightColor = "rgba(255, 255, 255, 0.3)";
  static no_data = [102, 102, 102, 1];


  //WEATHER STATIONS
  static weatherStations = {
    no_data: [102, 102, 102],
    temperature: {
      low: [0, 230, 230],
      mid: [255, 255, 255],
      high: [230, 46, 0]
    },
    rain: {
      low: [204, 224, 255],
      high: [0, 61, 153]
    },
    humidity: {
      low: [204, 255, 242],
      high: [0, 128, 96]
    }
  }

}

export const InvalidSymbol = {
  type: "simple-fill",
  style: "diagonal-cross",
  color: [255, 0, 0],
  outline: {
    color: [255, 0, 0],
    width: 4
  }
};
