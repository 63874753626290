import Axios, { AxiosInstance, AxiosPromise, AxiosResponse, AxiosBasicCredentials, AxiosRequestConfig } from "axios";
import { FactoryService as Factory } from "@/services/_base/factoryService";
import Qs from "qs";
import { unjoin } from "@/store/UTILS";
import { AlertHelper } from "./AlertHelper";
import { toDate } from "@/services/_base/utils";

/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
interface InterceptorRequestDelegate {
  (config: AxiosRequestConfig): AxiosRequestConfig;
}

/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
interface InterceptorResponseDelegate {
  (config: AxiosResponse): AxiosResponse;
}

/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
interface InterceptorErrorDelegate {
  (config: any): any;
}

/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
interface RequestInterceptorChangedDelegate {
  (item: InterceptorRequestDelegate): void;
}

/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
interface ResponseInterceptorChangedDelegate {
  (item: InterceptorResponseDelegate): void;
}

/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
interface ErrorInterceptorChangedDelegate {
  (item: InterceptorErrorDelegate): void;
}

/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
export class interceptorsConfig {
  requestInterceptors: InterceptorRequestDelegate[] = [];
  responseInterceptors: InterceptorResponseDelegate[] = [];
  errorsInterceptors: InterceptorErrorDelegate[] = [];
}

/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
export class sharedInterceptors extends interceptorsConfig {
  addRequestInterceptor(item: InterceptorRequestDelegate) {
    this.requestInterceptors.push(item);

    if (this.onRequestInterceptorChanged) this.onRequestInterceptorChanged(item);
  }

  addResponseInterceptor(item: InterceptorResponseDelegate) {
    this.responseInterceptors.push(item);

    if (this.onResponseInterceptorChanged) this.onResponseInterceptorChanged(item);
  }

  addErrorInterceptor(item: InterceptorErrorDelegate) {
    this.errorsInterceptors.push(item);

    if (this.onErrorInterceptorChanged) this.onErrorInterceptorChanged(item);
  }

  onRequestInterceptorChanged: RequestInterceptorChangedDelegate;
  onResponseInterceptorChanged: ResponseInterceptorChangedDelegate;
  onErrorInterceptorChanged: ResponseInterceptorChangedDelegate;
}
Factory.RegisterExplicit("sharedInterceptors", sharedInterceptors);


/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
export class baseRestService {

  protected allwaysSendAuthenticationToken: boolean = true;
  protected saveToSessionStorage: boolean = true;
  baseUrl: string = "";
  private _interceptors: sharedInterceptors = null;


  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  set interceptors(value: interceptorsConfig) {
    for (let i in value.requestInterceptors) this.http.interceptors.request.use(value.requestInterceptors[i]);

    for (let i in value.responseInterceptors) this.http.interceptors.response.use(value.responseInterceptors[i]);

    for (let i in value.errorsInterceptors) this.http.interceptors.response.use(r => r, value.errorsInterceptors[i]);
  }


  OnError: OnErrorDelegate;
  OnHeadersPreparing: OnHeadersPreparingDelegate;

  protected http: AxiosInstance;

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  constructor() {
    this.http = Axios.create();
    this._interceptors = Factory.GetByName("sharedInterceptors");

    // Initialize preregistered interceptors
    for (let i in this._interceptors.requestInterceptors) this.http.interceptors.request.use(this._interceptors.requestInterceptors[i]);

    for (let i in this._interceptors.responseInterceptors) this.http.interceptors.response.use(this._interceptors.responseInterceptors[i]);

    for (let i in this._interceptors.errorsInterceptors) this.http.interceptors.response.use((r) => { return r }, this._interceptors.errorsInterceptors[i]);

    this._interceptors.onRequestInterceptorChanged = (i) => this.http.interceptors.request.use(i);
    this._interceptors.onResponseInterceptorChanged = (i) => this.http.interceptors.response.use(i);
    this._interceptors.onErrorInterceptorChanged = (i) => this.http.interceptors.response.use(i);

    this.setArraySerializationMethod();
  }


  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  private setArraySerializationMethod() {
    this.http.interceptors.request.use(async (reqConfig) => {
      //change the default serializer only if the method is a GET
      if (reqConfig.method !== "get") {
        return reqConfig;
      }
      //the 'repeat' is the standard behavior for array: arrKey=x&arrKey=y&arrKey=z....
      reqConfig.paramsSerializer = (params) => {
        return Qs.stringify(params, { arrayFormat: "repeat" });
      };
      return reqConfig;
    });
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected async getRaw(uri: string, params: object = {}, sendAuthenticationToken: boolean = false): Promise<AxiosResponse> {
    AlertHelper.showLoader("loading...");

    let response = await this.http.get(this.baseUrl + uri,
      {
        headers: this.prepareHeaders(this.allwaysSendAuthenticationToken || sendAuthenticationToken, false),
        params: params
      });

    AlertHelper.hideLoader();
    if (response.status != 200 && this.OnError) this.OnError(response);
    return response;
  };

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected async get(uri: string, params: object = {}, sendAuthenticationToken: boolean = false): Promise<AxiosResponse> {
    AlertHelper.showLoader("loading...");

    let response = await this.http.get(this.baseUrl + uri,
      {
        headers: this.prepareHeaders(this.allwaysSendAuthenticationToken || sendAuthenticationToken, false),
        params: params,
        transformResponse: (resp => resp ? JSON.parse(resp, toDate) : null)
      });

    AlertHelper.hideLoader();
    if (response.status != 200 && this.OnError) this.OnError(response);
    return response;
  };

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected async Get<TResult>(uri: string, params: object = {}, sendAuthenticationToken: boolean = false): Promise<TResult> {
    let result = await this.get(uri, params, sendAuthenticationToken);
    if (result.status == 200)
      return result.data as TResult;
    else if (this.OnError) this.OnError(result);
    return null;
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected async post(uri: string, data: any, params: object = {}, sendAuthenticationToken: boolean = false): Promise<AxiosResponse> {
    let response = await this.http.post(this.baseUrl + uri, unjoin(data),
      {
        headers: this.prepareHeaders(this.allwaysSendAuthenticationToken || sendAuthenticationToken, true),
        params: params,
        transformResponse: (resp => resp ? JSON.parse(resp, toDate) : null)
      } as AxiosRequestConfig);
    if (response.status != 200 && this.OnError) this.OnError(response);
    return response;
  };

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected async Post<TResult>(uri: string, data: any, params: object = {}, sendAuthenticationToken: boolean = false): Promise<TResult> {
    let result = await this.post(uri, data, params, sendAuthenticationToken);
    if (result.status == 200)
      return result.data as TResult;
    else if (this.OnError) this.OnError(result);
    return null;
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected async put(uri: string, data: any, params: object = {}, sendAuthenticationToken: boolean = false): Promise<AxiosResponse> {
    let response = await this.http.put(this.baseUrl + uri, unjoin(data),
      {
        headers: this.prepareHeaders(this.allwaysSendAuthenticationToken || sendAuthenticationToken, true),
        params: params,
        transformResponse: (resp => resp ? JSON.parse(resp, toDate) : null)
      });
    if (response.status != 200 && this.OnError) this.OnError(response);
    return response;
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected async Put<TResult>(uri: string, data: any, params: object = {}, sendAuthenticationToken: boolean = false): Promise<TResult> {
    let result = await this.put(uri, data, params, sendAuthenticationToken);
    if (result.status == 200)
      return result.data as TResult;
    else if (this.OnError) this.OnError(result);
    return null;
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected async delete(uri: string, params: object = {}, sendAuthenticationToken: boolean = false): Promise<AxiosResponse> {
    let response = await this.http.delete(this.baseUrl + uri,
      {
        headers: this.prepareHeaders(this.allwaysSendAuthenticationToken || sendAuthenticationToken, false),
        params: params,
        transformResponse: (resp => resp ? JSON.parse(resp, toDate) : null)
      });
    if (response.status != 200 && this.OnError) this.OnError(response);
    return response;
  }


  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected async Delete<TResult>(uri: string, params: object = {}, sendAuthenticationToken: boolean = false): Promise<TResult> {
    let result = await this.delete(uri, params, sendAuthenticationToken);
    if (result.status == 200)
      return result.data as TResult;
    else if (this.OnError) this.OnError(result);
    return null;
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected prepareHeaders(auth: boolean = false, json: boolean = true): any {

    let headers: any = {};

    if (auth) {
      let authData = this.getAuthenticationToken();
      if (authData) {
        headers['Authorization'] = 'Bearer ' + authData.access_token;
      }
    }
    if (json) headers['Content-Type'] = 'application/json';

    if (this.OnHeadersPreparing) this.OnHeadersPreparing(headers);
    return headers;
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected getAuthenticationToken(): AuthToken {
    // let wstore = window.sessionStorage;
    // let sstore = window.localStorage;
    // return JSON.parse(wstore.getItem("authorizationData") || sstore.getItem("authorizationData") || null) as AuthToken
    return baseRestService.getAuthenticationToken();
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  public static getAuthenticationToken(): AuthToken {
    let wstore = window.sessionStorage;
    let sstore = window.localStorage;
    return JSON.parse(wstore.getItem("authorizationData") || sstore.getItem("authorizationData") || null) as AuthToken
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected setAuthenticationToken(data: AuthToken) {
    let storage: any = this.saveToSessionStorage ? window.sessionStorage : window.localStorage;
    storage.setItem("authorizationData", JSON.stringify(data));
  }

  /// ----------------------------------------------------------------------------  ///
  ///                                 ATTENZIONE                                    ///
  ///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
  /// ----------------------------------------------------------------------------  ///
  protected deleteAuthenticationToken() {
    window.sessionStorage.removeItem("authorizationData");
    window.localStorage.removeItem("authorizationData");
  }
}

export interface OnErrorDelegate { (data: DataResponse): void; }
interface OnHeadersPreparingDelegate { (headers: Headers): void; }

/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
export class AuthToken {
  access_token: string;
  refresh_token: string;
  id_token: string;
  expires_in: number;
  expiration_date: number;
  resource: string;
  userName: string;
  token_type: string;
}

/// ----------------------------------------------------------------------------  ///
///                                 ATTENZIONE                                    ///
///            VIETATO APPORTARE QUALSIASI MODIFICA A QUESTO METODO O CLASSE      ///
/// ----------------------------------------------------------------------------  ///
export class DataResponse {
  status: number;
  statusText: string;
  data: any;
}
