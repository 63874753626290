import { propertyResolve, weatherStoreGetters, stationStoreGetters } from "@/store";
import { weatherStationService } from "@/services";
import { Esri } from "@/esriMap";
import moment from "moment";
export class WeatherStationIntersection implements server.station {
  sensors: any[];
  sensorsConfiguration: number[];
  organizationId: string;
  lastSyncronizationDate: Date;
  stationType: number;
  timeZone: number;
  id: string;
  name: string;
  producer: string;
  description: string;
  latitude: number;
  longitude: number;
  removalDate: Date;
  syncInterval: number;
  disabled: boolean;

  static pointConstructor: __esri.PointConstructor = null;

  get state(): "weather-station-off" | "weather-station-working" | "weather-station-warning" | "weather-station-error" {
    if(this.disabled)
      return "weather-station-off";

    var result = Math.abs(moment(this.lastSyncronizationDate).diff(moment(), "days"));
    if (result <= 2 && result > 1) return "weather-station-warning";
    else if (result > 2) return "weather-station-error";
    else return "weather-station-working";
  }

  constructor() {
    this.Initialize();
  }

  async Initialize() {
    WeatherStationIntersection.pointConstructor = await Esri.Geometry.PointConstructor();
  }

  summary(): server.summary {
    return weatherStoreGetters.summary(this.id);
  }

  // get lastDailyMeasure() {
  //   return propertyResolve(weatherStoreGetters.lastDailyMeasure(this.id),
  //     () => weatherStationService.GetLastDailyMeasure(this.id), this.id);
  // }

  lastMeasure() {
    return weatherStoreGetters.lastMeasure(this.id);
    // return propertyResolve(weatherStoreGetters.lastMeasure(this.id),
    //   () => weatherStationService.GetLastMeasure(this.id), this.id);
  }

  get configuredSensors() {
    return propertyResolve(stationStoreGetters.getSensorsByTypes(this.sensorsConfiguration), () => weatherStationService.ListSensorsTypes(), this.sensorsConfiguration.join(','));
  }

  get stationTypeItem() {
    //return  weatherStationService.ListStationsTypes().then(ii=>ii.find(id=>id.id == this.stationType) )
    return propertyResolve(stationStoreGetters.getStationsByTypes(this.stationType), () => weatherStationService.ListStationsTypes(), this.stationType);
  }

  geometryESRI() {
    if (WeatherStationIntersection.pointConstructor && this.latitude && this.longitude) return new WeatherStationIntersection.pointConstructor({ latitude: this.latitude, longitude: this.longitude });
    return null;
  }
}

export const weatherStationIntersection = new WeatherStationIntersection();
