import { baseRestService } from "@/services/_base/baseRestService";
import Config from "@/config"
import { rolesStoreActions } from "@/store";

class RolesService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  public async List(roleId: string = null, skip: number = 0, take: number = 1000): Promise<server.roleDetail[]> {
    var result = await this.Get<server.roleDetail[]>("/roles", { roleId, skip, take });
    rolesStoreActions.setRoles(result);
    return result;
  }

  public async Manage(role: server.roleDetail): Promise<server.roleDetail> {
    var result = await this.Post<server.roleDetail>("/roles", role);
    rolesStoreActions.setRoles([result]);
    return result;
  }

  public async Remove(roleId: string): Promise<any> {
    var result = await this.Delete<any>(`/roles/${roleId}`);
    rolesStoreActions.removeRole(roleId);
    return result;
  }
}
export const rolesService = new RolesService();
