//lazy loading vue components
//https://router.vuejs.org/en/advanced/lazy-loading.html
//to make this work we also need the syntax-dynamic-import
//https://babeljs.io/docs/plugins/syntax-dynamic-import/

// layout
import Full from '@/layouts/Full.vue'

//base views
import Dashboard from '@/app_modules/dashboard/components/Dashboard.vue'
import AuthRedirect from '@/components/authRedirect/authRedirect'


function injectRoutes(mainRoute, childroutes) {
    for (const key in childroutes) {
        const element = childroutes[key];
        mainRoute[0].children.push(element);
    }
}

const routes = [{
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
    meta: { requireAuth: true, track: false },
    component: Full,
    children: [{
        path: 'dashboard',
        name: 'dashboard',
        meta: { track: false },
        component: Dashboard
    }]
},
{
    path: '/authredirect',
    meta: { requireAuth: false, track: false },
    component: AuthRedirect
}
]

import { route as weatherRoutes } from "@/app_modules/weather/routes";
injectRoutes(routes, weatherRoutes);

import { route as bulletinsRoutes } from "@/app_modules/bulletins/routes";
injectRoutes(routes, bulletinsRoutes);

import { route as dashboardRoutes } from "@/app_modules/dashboard/routes";
injectRoutes(routes, dashboardRoutes);


import { route as usersManagementRoutes } from "@/app_modules/users-management/routes";
injectRoutes(routes, usersManagementRoutes);

import { route as areals } from "@/app_modules/areals/routes";
injectRoutes(routes, areals);

import { route as suggestions } from "@/app_modules/suggestions/routes";
injectRoutes(routes, suggestions);

import { route as mailing } from "@/app_modules/mailing-lists/routes";
injectRoutes(routes, mailing);

export default routes;
