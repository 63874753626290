import { baseRestService, sharedInterceptors } from "@/services/_base/baseRestService";
import { Factory } from "@/services/_base";
import Config from "@/config";
import { localizationStoreActions, store,  getStoreState } from "@/store";



export class LocalizationService extends baseRestService {

  get currentLanguage() { return getStoreState().localization.currentLanguage; }
  set currentLanguage(value: string) {
    window.localStorage.setItem("current-language", value);

    this.getLabels(value).then(r => {
      localizationStoreActions.changeLanguage({ newLanguage: value, labels: r });

    });
  }



  public supportedLanguages: Array<String>


  constructor() {
    super();

    this.baseUrl = Config.localizationServerUrl;
    this.initInterceptors();
    this.currentLanguage = window.localStorage.getItem("current-language") || (navigator.language || (navigator as any).userLanguage).substring(0, 2) || "handshaking";
  }

  initInterceptors() {
    Factory.GetExplicit<sharedInterceptors>("sharedInterceptors").addRequestInterceptor(i => {
      if (this.currentLanguage) {
        if (i.url.indexOf("/localization/handshake") === -1 || window.localStorage.getItem("Agricolus-Culture-User-Selected"))
          i.headers["Agricolus-Culture"] = this.currentLanguage;
      }
      return i;
    });
  }

  async handshake(): Promise<void> {
    let result = await this.Get<server.localization.handshake>("/localization/handshake");
    // if(this.currentLanguage == "handshaking")
    //   this.currentLanguage = result.current;

    this.supportedLanguages = result.supported;

    if (this.supportedLanguages.indexOf(this.currentLanguage) < 0)
      this.currentLanguage = result.current;

    localizationStoreActions.setSupportedLanguages(result.supported);
  }

  async getLabels(lang?: string): Promise<server.localization.label[]> {
    if (!lang)
      lang = this.currentLanguage;
    return await this.Get<server.localization.label[]>("/localization/" + lang + "/labels");
  }

  async setLabel(key: string, value: string, group?: string, lang?: string): Promise<server.localization.label> {
    if (!lang)
      lang = this.currentLanguage;

    let obj = { stringCode: key, stringValue: value, group: group || "agr_fe_commons", languageCode: lang } as server.localization.label;
    let result = await this.Post<server.localization.label>("/localization/" + this.currentLanguage + "/labels", obj);
    return result;
    // return null;
  }

  async getTranslations(key: string): Promise<any> {
    let params = {
      stringCode: key
    };
    let result = await this.Get<any>("/localization/translations", params);
    return result;
  }

  async saveTranslations(data): Promise<any> {
    await this.Post<any>("/localization/translations", data);
  }
}


export const localizationService = new LocalizationService();


