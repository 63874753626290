import { baseRestService } from "@/services/_base/baseRestService";
import { securityService } from '@/services'
import Config from "@/config"
import { weatherStoreActions, stationStoreActions } from '@/store'
import moment from "moment"

export class WeatherStationService extends baseRestService {
  constructor() {
    super();
  }

  async List(state: "weather-station-off" | "weather-station-working" | "weather-station-warning" | "weather-station-error" = "weather-station-working"): Promise<server.station[]> {
    let active: string = null
    switch (state) {
      case "weather-station-working":
        active = "on";
        break;
      case "weather-station-off":
        active = "off";
        break;
      case "weather-station-warning":
        active = "warning";
        break;
      case "weather-station-error":
        active = "error";
        break;
      default:
        break;
    }

    var result = await this.Get<server.station[]>(Config.appServerCDNUrl + "/meteo/stations", { active: active });
    weatherStoreActions.setStations(result);
    return result;
  }

  async GetStation(stationId: string): Promise<server.station> {

    var params = {
      organizationId: 1 //FIXME: organizationId va gestito in qualche modeo... securityService.getUserOrganizationId()
    };

    var result = await this.Get<server.station>(Config.appServerUrl + "/meteo/station/" + stationId, params);
    weatherStoreActions.setStation(result);
    return result;
  }

  async GetStationSummary(stationId: string, at: Date = null): Promise<server.summary> {
    var params = {
      organizationId: securityService.getUserOrganizationId(),
      stationId: stationId,
      at: at
    };

    let result = await this.Get<server.summary>(Config.appServerCDNUrl + "/meteo/measures/summary", params);
    weatherStoreActions.setStationSummary({ stationId: stationId, summary: result });
    return result;
  }

  async GetNearestStation(longitude: number, latitude: number): Promise<server.station> {
    var params = {
      organizationId: securityService.getUserOrganizationId(),
      longitude: longitude,
      latitude: latitude,
    };

    var result = await this.Get<server.station>(Config.appServerCDNUrl + "/meteo/stations/nearest", params);
    weatherStoreActions.setStation(result);
    return result;
  }

  async GetLastMeasure(stationId: string): Promise<server.measureDetail> {

    var params = {
      organizationId: securityService.getUserOrganizationId(),
      stationId: stationId,
    };

    var result = await this.Get<server.measureDetail>(Config.appServerCDNUrl + "/meteo/measures/last", params);

    weatherStoreActions.setWeatherMeasure(result);
    return result;
  }

  async GetLastDailyMeasure(stationId: string): Promise<server.dailyMeasureDetail> {

    var params = {
      organizationId: securityService.getUserOrganizationId(),
      stationId: stationId,
    };

    var result = await this.Get<server.dailyMeasureDetail[]>(Config.appServerCDNUrl + "/meteo/measures/daily", params);
    if (result && result.length > 0) {
      weatherStoreActions.setDailyWeatherMeasure(result[0]);
      return result[0];
    }
    return null;
  }

  async GetDailyMeasure(stationId: string, from: Date, to: Date = null): Promise<server.dailyMeasureDetail[]> {

    var params = {
      organizationId: securityService.getUserOrganizationId(),
      stationId: stationId,
      fromdate: from,
      todate: to
    };

    var data = await this.Get<server.dailyMeasureDetail[]>(Config.appServerCDNUrl + "/meteo/measures/daily", params);
    weatherStoreActions.setCurrentMeasure(data);
    // weatherStoreActions.setDailyWeatherMeasure(result[0]);
    return data;

  }


  async GetArealMeasure(arealId: string, from: Date, to: Date = null, excludeSuspectData: boolean = true,): Promise<server.dailyMeasureDetail[]> {
    var params = {
      organizationId: securityService.getUserOrganizationId(),
      arealId: arealId,
      from: from,
      to: to,
      ts: new Date().getTime(),
      excludeSuspectData: excludeSuspectData
    };



    var data = await this.Get<server.dailyMeasureDetail[]>(Config.appServerCDNUrl + "/meteo/measures/interval/areal", params);
    return data;
  }



  async GetWeeklyMeasure(stationId: string, from: Date, to: Date = null): Promise<server.weeklyMeasureDetail[]> {

    var params = {
      organizationId: securityService.getUserOrganizationId(),
      stationId: stationId,
      fromdate: from,
      todate: to
    };

    var data = await this.Get<server.weeklyMeasureDetail[]>(Config.appServerCDNUrl + "/meteo/measures/weekly", params);
    weatherStoreActions.setCurrentMeasure(data);
    // weatherStoreActions.setDailyWeatherMeasure(result[0]);
    return data;

  }

  async GetMonthlyMeasure(stationId: string, from: Date, to: Date = null): Promise<server.monthlyMeasureDetail[]> {

    var params = {
      organizationId: securityService.getUserOrganizationId(),
      stationId: stationId,
      fromdate: from,
      todate: to
    };

    var data = await this.Get<server.monthlyMeasureDetail[]>(Config.appServerCDNUrl + "/meteo/measures/monthly", params);
    weatherStoreActions.setCurrentMeasure(data);
    return data;

  }

  async ListMeasures(stationId: string, from: Date, to: Date): Promise<server.measureDetail[]> {
    var dateFormat = 'YYYY-MM-DDTHH:mm:ss';
    var params = {
      organizationId: securityService.getUserOrganizationId(),
      stationId: stationId,
      from: moment(from).format(dateFormat),
      to: moment(to).format(dateFormat)
    };
    var result = await this.Get<server.measureDetail[]>(Config.appServerCDNUrl + "/meteo/measures/interval", params);
    weatherStoreActions.setCurrentMeasure(result);
    return result;
  }


  //METODI DI ADMIN
  async ListStationsTypes(): Promise<server.stationType[]> {
    let result = await this.Get<server.stationType[]>(Config.appServerUrl + "/meteo/stations/types");
    stationStoreActions.setStationTypes(result);

    return result;
  }
  async ListDataProviders(): Promise<server.stationProvider[]> {
    var result = await this.Get<server.stationProvider[]>(Config.appServerUrl + "/meteo/stations/dataproviders");

    stationStoreActions.setDataProviders(result);

    return result;
  }
  async ListSensors(): Promise<server.stationSensor[]> {
    let result = await this.Get<server.stationSensor[]>(Config.appServerUrl + "/meteo/sensors");

    stationStoreActions.setSensors(result);

    return result;
  }
  async ListSensorsTypes(): Promise<server.stationSensorType[]> {
    let result = await this.Get<server.stationSensorType[]>(Config.appServerUrl + "/meteo/sensors/types");

    stationStoreActions.setSensorTypes(result);

    return result;
  }
  async ListSensorsTemplate(): Promise<server.stationSensorTemplate[]> {
    let result = await this.Get<server.stationSensorTemplate[]>(Config.appServerUrl + "/meteo/sensortemplates");

    stationStoreActions.setSensorTemplates(result);
    return result;
  }
  async GetAllSensorTemplates(template: string): Promise<server.stationSensor[]> {
    let result = await this.Get<server.stationSensor[]>(Config.appServerUrl + "/meteo/sensortemplates/detail", { template: template });
    return result;
  }
  async Update(organizationId: string, id: string, obj: server.station): Promise<server.station> {
    let result = await this.Put<server.station>(Config.appServerUrl + "/meteo/station/" + id + "?organizationId=" + organizationId, obj);

    stationStoreActions.setStationHW(result);

    return result;
  }
  async Create(organizationId: string, obj: server.station): Promise<server.station> {
    let result = await this.Post<server.station>(Config.appServerUrl + "/meteo/station?organizationId=" + organizationId, obj);

    stationStoreActions.setStationHW(result);

    return result;
  }
  async Remove(organizationId: string, id: string): Promise<any> {
    let result = await this.Put<any>(Config.appServerUrl + "/meteo/station/" + id + "/changestatus?organizationId=" + organizationId, null);
    stationStoreActions.removeStationHW(id);
    weatherStoreActions.removeStation(id);
    return result;
  }
  async UpdateStationDataProvider(organizationId: string, obj: server.stationProvider): Promise<server.stationProvider> {
    let result = await this.Post<server.stationProvider>(Config.appServerUrl + "/meteo/station/" + obj.id + "/dataprovider?organizationId=" + organizationId, obj);

    stationStoreActions.setDataProvider(result);

    return result;
  }
  async CreateSensor(organizationId: string, id: string, obj: server.stationSensor): Promise<server.stationSensor> {
    let result = await this.Post<server.stationSensor>(Config.appServerUrl + "/meteo/station/" + id + "/sensor?organizationId=" + organizationId, obj);

    stationStoreActions.setSensor(result);

    return result;
  }
  async UpdateSensor(organizationId: string, id: string, obj: server.stationSensor): Promise<server.stationSensor> {
    let result = await this.Put<server.stationSensor>(Config.appServerUrl + "/meteo/station/" + id + "/sensor/" + obj.id + "?organizationId=" + organizationId, obj);

    stationStoreActions.setSensor(result);

    return result;
  }
  async RemoveSensor(organizationId: string, stationId: string, id: string): Promise<void> {
    let result = await this.Delete<void>(Config.appServerUrl + "/meteo/station/" + stationId + "/sensor/" + id + "?organizationId=" + organizationId);

    stationStoreActions.removeSensor(id);

    return result;
  }

  //servizi specifici per PESSL
  //NOTA: dovremo rifattorizzare nel momento in cui andrò ad aggiungere altri fornitori di stazioni meteo
  async GetPesslStations(authorizationkey: String, authorizationsecret: String): Promise<any[]> {
    return await this.Get<any[]>(Config.appServerUrl + "/pessl/stations?public_key=" + authorizationkey + "&private_key=" + authorizationsecret);
  }
  async GetPesslStationDetails(authorizationkey: String, authorizationsecret: String, stationId: String): Promise<any[]> {
    return await this.Get<any[]>(Config.appServerUrl + "/pessl/stations/" + stationId + "/sensors?public_key=" + authorizationkey + "&private_key=" + authorizationsecret);
  }
}

export const weatherStationService = new WeatherStationService();
