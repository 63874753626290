
declare var API_URL: string;
declare var AUTH_URL: string;
declare var CLIENT_ID: string;
declare var CDN_URL: string;

export default class Config {
  static showTranslationWarnings: boolean = false;
  static arcgisPortal: string = "https://www.arcgis.com/sharing/rest/portals/self?f=json";
  static clientSideUrl: string = window.location.origin;
  static authorizationServerUrl: string = AUTH_URL;
  static appServerUrl: string = API_URL + "/api";
  static appServerCDNUrl: string = CDN_URL + "/api";
  static appSignalrHub: string = API_URL + "/signalr"
  static storageImageServerUrl: string = API_URL + "/api";
  static localizationServerUrl: string = API_URL + "/api";
  static subscriptionServerUrl: string = AUTH_URL;

  static arcgisImageServer: 'https://landsat2.arcgis.com/arcgis/rest/services/Landsat8_Views/ImageServer';
  static signinCallbackRoute: string = "/auth_callback.html";
  static maxZoomForMapScreenshots: Number = 18;

  static languages = {
    current: "en",
    formatter: {
      en: {
        icon: '/assets/img/flags/en.png',
        text: 'English'
      },
      it: {
        icon: '/assets/img/flags/it.png',
        text: 'Italiano'
      },
      fr: {
        icon: '/assets/img/flags/fr.png',
        text: 'Français'
      },
      pt: {
        icon: '/assets/img/flags/pt.png',
        text: 'Português'
      },
      es: {
        icon: '/assets/img/flags/es.png',
        text: 'Español'
      },
      tr: {
        icon: '/assets/img/flags/tr.png',
        text: 'Türk'
      }
    }
  }

  static defaultLanguage: string = "en";

  public static authorizationConfig = {
    checkSessionInterval: 30000,
    renewSessionTimeLimit: 1000,
    client_id: CLIENT_ID || "smartmeteo",
    openid_scope: "openid profile email"
  }
}
