const Index = () => import( /* webpackChunkName: "suggestions" */ "./components/index.vue");
const SuggestionEditor = () => import( /* webpackChunkName: "suggestions" */ "./components/suggestionEditor.vue");

export const route = [
  {
    path: 'suggestions/:productionChainId?',
    name: 'suggestions',
    component: Index,
    props: true
  },
  {
    name: "suggestions:addnew",
    path: "suggestions/addnew",
    meta: {
      track: true,
      featureFlag: "suggestion_editor"
    },
    props: false,
    component: SuggestionEditor,
    children: []
  },
  {
    name: "suggestions:edit",
    path: "suggestions/edit/:suggestionId",
    meta: {
      track: true,
      featureFlag: "suggestion_editor"
    },
    props: true,
    component: SuggestionEditor,
    children: []
  },
];
