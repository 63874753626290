import Vue from "vue";
import Component from "vue-class-component";

import CardDashboardButton from "../../../layouts/cards/card-dashboard-button.vue";
import { productionChainsStoreGetters, usersStoreGetters } from "@/store";

@Component({
  components: { CardDashboardButton }
})
export default class Dashboard extends Vue {
  get fullAccess(): boolean {
    return this.userIsInRole('administrator') || this.userIsInRole('owner');
  }

  get productionChains(){
    const all = productionChainsStoreGetters.getProductionChains();
    if(!this.fullAccess)
      return all.filter(f => this.currentUser.productionChains.map(m => m.id).includes(f.id)) || [];
    return all;
  }

  get currentUser(): IUserDetail {
    return usersStoreGetters.currentUser();
  }

  userIsInRole(role: string){
    return usersStoreGetters.userIsInRole(role);
  }

}
