import iziToast, { IziToastSettings } from "izitoast";
import { localizeFunction } from "src/filters/localize";

export class AlertHelper {
  public static showInfo(title: string, description: string, timeout: number | boolean = 5000) {
    try {
      iziToast.success({
        timeout: timeout,
        theme: "dark",
        title: localizeFunction(title),
        message: localizeFunction(description),
        class: "agr-white",
        icon: "fa fa-check fa-2x",
        position: "topLeft",
        progressBarEasing: 'linear',
        progressBar: true,
        progressBarColor: "#c9243f",
        closeOnEscape: false,
        drag: false,
      } as any);
    } catch (err) {
      console.debug("show info error: ", err);
    }
  }

  private static operation: number = 0;
  public static showLoader(info: string) {
    const toast = document.querySelector("#toastLoading") as any;
    AlertHelper.operation++;
    if (toast) {
      try {
        iziToast.progress({}, toast).pause();
        iziToast.progress({}, toast).reset();
      } catch (err) {
        console.error(err);
      }
      return;
    }

    try {
      iziToast.info({
        id: "toastLoading",
        timeout: 1000,
        icon: "fas fa-sync fa-spin fa-fw",
        position: "bottomLeft",
        toastOnce: true,
        theme: "dark",
        title: localizeFunction(info),
        close: false,
        width: 200,
        transitionIn: "fadeIn",
        transitionOut: "fadeOut",
        progressBarEasing: 'linear',
        progressBar: true,
        progressBarColor: "#c9243f",
        closeOnEscape: false,
        drag: false,
        onClosed: function () {
          AlertHelper.operation = 0;
        }
      } as any);
    } catch (err) { }
  }

  public static hideLoader() {
    setTimeout(() => {
      AlertHelper.operation--;
      var toast = document.querySelector('#toastLoading') as any;
        try {
          if (AlertHelper.operation < 1 && toast) {
            iziToast.progress({}, toast).resume();
          }
        } catch (err) { console.log(err) }
    }, 500);
  }

  public static showError(title: string, description: string, timeout: number | boolean = 30000, position: string = "topLeft") {
    try {
      iziToast.error({
        timeout: timeout, theme: "dark",
        title: localizeFunction(title),
        message: description,
        class: "agr-error",
        icon: "fa fa-exclamation-circle fa-2x",
        position: position,
        progressBarEasing: 'linear',
        progressBar: true,
        progressBarColor: "#c9243f",
        closeOnEscape: false,
        drag: false,
      } as any);
    } catch (err) {
      console.debug("show error", err);
    }
  }
}
