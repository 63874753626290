import { baseRestService } from "./_base/baseRestService";
import Config from "@/config";
import { bulletinsStoreActions } from "@/store";

class ArealsService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  public async List(skip: number = 0, take: number = 1000): Promise<server.areal[]> {
    var result = await this.Get<server.areal[]>("/meteo/areals", { skip, take });
    bulletinsStoreActions.setAreals(result)
    return result;
  }

  public async GetItem(id: string): Promise<server.arealDetails> {
    var result = await this.Get<server.arealDetails>(`/meteo/areals/${id}`);
    return result;
  }

  public async Save(areal: server.arealDetails): Promise<server.arealDetails> {
    let result = null;
    if (areal.id)
      result = await this.Put<server.arealDetails>("/meteo/areals", areal);
    else result = await this.Post<server.arealDetails>("/meteo/areals", areal);
    bulletinsStoreActions.setAreal(result)
    return result;
  }

  public async Remove(id: string): Promise<any> {
    var result = await this.Delete<any>(`/meteo/areals/${id}`);
    return result;
  }

  public async AddStation(arealId: string, stationId: string): Promise<server.bulletin> {
    var result = await this.Post<server.bulletin>(`/meteo/areals/${arealId}/station/${stationId}`, null);
    return result;
  }

  public async SetDefault(arealId: string, stationId: string, isdefault: boolean): Promise<server.bulletin> {
    var result = await this.Post<server.bulletin>(`/meteo/areals/${arealId}/station/${stationId}/default/${isdefault}`, null);
    return result;
  }

  public async RemoveStation(arealId: string, stationId: string): Promise<any> {
    var result = await this.Delete<any>(`/meteo/areals/${arealId}/station/${stationId}`);
    return result;
  }
}
export const arealsService = new ArealsService();