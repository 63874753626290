<template>
  <li class="nav-item" v-if="! disabled">
    <router-link :to="url" class="nav-link">
      <i :class="iconClass"></i> {{ name | localize }}
      <span v-if="badge" class="badge" :class="badgeClass">{{ badge }}</span>
    </router-link>
  </li>
  <li class="nav-item" v-else>
    <a class="nav-link nav-link-disabled">
      <i :class="iconClass" class="icon-menu"></i> {{ name | localize }} 
      <i class="fa fa-info-circle icon" v-tooltip="{ content: desc, trigger: 'click' }"></i>
    </a>
  </li>
</template>

<script>
  export default {
    data: function() {
      return {
        localGroup: "sidebar"
      }
    },
    props: {
      disabled: { default: false },
      url: { default: "" },
      desc: { default: "" },
      iconClass: { default: "" },
      name: { default: "" },
      badge: { default: "" },
      badgeClass: { default: "" },
    }
  };
</script>