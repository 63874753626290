const Index = () => import( /* webpackChunkName: "mailingLists" */ "./components/index.vue");
const ListEditor = () => import( /* webpackChunkName: "mailingLists" */ "./components/listDetails.vue");


export const route = [
  {
    path: 'mailing',
    name: 'mailing-lists',
    component: Index
  },
  {
    name: "mailing-lists:addnew",
    path: "mailing/addnew",
    meta: {
      track: true,
      featureFlag: "mailing_list"
    },
    props: false,
    component: ListEditor,
    children: []
  },
  {
    name: "mailing-lists:edit",
    path: "mailing/edit/:listId",
    meta: {
      track: true,
      featureFlag: "mailing_list"
    },
    props: true,
    component: ListEditor,
    children: []
  },
];
