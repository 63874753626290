import { fillArray } from "@/store";
import { CreateActionsHandler, CreateGettersHandler } from "./UTILS";


export interface SuggestionsStoreModel {
  suggestions: Array<server.suggestion>;
  tempImages: Map<string, Blob>;
}

interface SuggestionsStoreActions {

  setSuggestions: (suggestions: server.suggestion[]) => void;
  setSuggestion: (suggestion: server.suggestion) => void;
  setTempImage: (image: { url: string, blob: Blob }) => void;
  removeSuggestion: (suggestionId: string) => void;
}

interface SuggestionsStoreGetters {

  getSuggestions: () => server.suggestion[];
  getSuggestion: (suggestionId: string) => server.suggestion;
  getTempImage: (url: string) => Blob;
}

export const suggestionsStore = {
  PREFIX: "suggestions",
  namespaced: true,
  state: {
    suggestions: new Array<server.suggestion>(),
    _suggestions: new Map<string, server.suggestion>(),
    tempImages: new Map<string, Blob>()
  },
  getters: {
    getSuggestions: (state: SuggestionsStoreModel) => (): server.suggestion[] => state.suggestions,
    getSuggestion: (state: SuggestionsStoreModel) => (suggestionId: string): server.suggestion => state.suggestions.find(u => u.id === suggestionId),
    getTempImage: (state: SuggestionsStoreModel) => (url: string): Blob => state.tempImages.get(url)
  },
  mutations: {
    setSuggestions(state, suggestions: server.suggestion[]) {
      state._suggestions = new Map<string, server.suggestion>();
      for (const key in suggestions) {
        const suggestion = suggestions[key];
        state._suggestions.set(suggestion.id, suggestion);
      }
      fillArray(state.suggestions, state._suggestions.values());
    },
    setSuggestion(state, suggestion: server.suggestion) {
      state._suggestions.set(suggestion.id, suggestion);
      fillArray(state.suggestions, state._suggestions.values());
    },
    setTempImage(state, data: any) {
      state.tempImages.set(data.url, data.blob);
    },
    removeSuggestion(state, data: string) {
      state._suggestions.delete(data);
      fillArray(state.suggestions, state._suggestions.values());
    }
  },
  actions: {
    setSuggestions(context, suggestions: server.suggestion[]) {
      context.commit("setSuggestions", suggestions);
    },
    setSuggestion(context, suggestion: server.suggestion) {
      context.commit("setSuggestion", suggestion);
    },
    setTempImage(context, image: { url: string, blob: Blob }) {
      context.commit("setTempImage", image);
    },
    removeSuggestion(context, suggestionId: string) {
      context.commit("removeSuggestion", suggestionId);
    },
  }
}

export const suggestionsStoreActions = CreateActionsHandler<SuggestionsStoreActions>(suggestionsStore);
export const suggestionsStoreGetters = CreateGettersHandler<SuggestionsStoreGetters>(suggestionsStore);

