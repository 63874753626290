import Vue from "vue";
import * as pf from "sprintf-js";
import Config from "@/config";
import {
  store
} from "src/store";

Vue.filter("localize", localizeFunction);

export function localizeFunction(translationKey, translationGroup, ...args) {

  if (!translationKey && translationKey !== "" && DEVELOPMENT && Config.showTranslationWarnings) {
    let stack = new Error().stack;
    console.warn('[WARN: localizeFunction - empty translationKey]', stack);
    return "";
  }

  translationKey = translationKey.toLowerCase();
  if (typeof translationGroup == undefined) translationGroup = null;

  if (translationGroup) translationGroup = translationGroup.toLowerCase();

  if (!store.state.localization.enabled) return "¡" + (translationGroup ? translationGroup + "." + translationKey : translationKey) + "¡";

  let value = store.getters["localization/getLocalizedLabel"](translationKey, translationGroup);

  if (!value && value !== "" && DEVELOPMENT  && Config.showTranslationWarnings) {
    console.warn('[WARN: localizeFunction - no translation] ' + translationKey);
  }

  if (args && args.length > 0) return pf.vsprintf(value || translationKey, args);
  return value || translationKey;
}
