import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import router from "@/router";

import { WeatherStationIntersection } from "@/store/intersections/weatherstation.intersection";
import { weatherForecastService, weatherStationService } from "@/services";
import moment from "moment";

@Component({})
export default class WeatherstationPopupTemplate extends Vue {
  @Prop()
  stationData: WeatherStationIntersection
  lastMeasure: any = null;
  forecast: any = null;

  @Watch("stationData")
  watcherStationData() {
    this.getStationData()
  }



  goDetails() {
    router.push({ name: "weather:station", params: { stationId: this.stationData.id } });
  }

  goForecasts() {
    router.push({ name: "weather:forecasts:details", params: { id: this.stationData.id } });
  }

  private getStationData() {
    weatherForecastService.Current(this.stationData.latitude, this.stationData.longitude, 1).then((item) => {
      this.forecast = {
        date: moment(item.date).format("lll"),
        condition: item.condition,
        icon: item.icon.replace("http://", "https://"),
        sensors: item.sensors.sort((a, b) => a.key.localeCompare(b.key)).map((sensorItem) => {
          return {
            sensor: sensorItem.key,
            value: sensorItem.key === "wind" ? sensorItem.value : this.round(sensorItem.value, 1),
            unit: sensorItem.key === "wind" ? item.units.windspeed : item.units[sensorItem.key],
          }
        })
      };
    });

    weatherStationService.GetLastDailyMeasure(this.stationData.id).then((f) => {
      if (f) {
        this.lastMeasure = {
          date: moment(f.date).format("lll"),
          sensors: {
            avgTemperature: this.round(f.avgTemperature, 1),
            minTemperature: this.round(f.minTemperature, 1),
            maxTemperature: this.round(f.maxTemperature, 1),
            precipitations: this.round(f.precipitations, 1),
          } as server.dailyMeasureDetail
        };
      }
    });
  }

  private round(value, digits: number = 1) {
    if (value === null || value === undefined)
      return value;
    let exp = Math.pow(10, digits);
    return Math.round(value * exp) / exp;
  }
};
