<template>
    <img v-lazy="computedSrc"
               :width="width"
               :height="height"
               :default-image="defaultImage"

               :alt="alt"
               @load="srcLoaded"
               @error="srcError"
               >

</template>
<script>
import Config from '../../config';

export const props = {
  src: {
    type: String,
    default: null
  },
  alt: {
    type: String,
    default: null
  },
  width: {
    type: [Number, String],
    default: null
  },
  height: {
    type: [Number, String],
    default: null
  },
  format: {
    type: String,
    default: 'jpg'
  },
  stretch: {
    type: Boolean,
    default: false
  },
  crop: {
    type: Boolean,
    default: false
  },
  cropfrom: {
    type: String,
    default: null
  },
  quality: {
    type: Number,
    default: 100
  },
  defaultImage: {
    type: String,
    default: ''
  },
  left: {
    type: Boolean,
    default: false
  },
  right: {
    type: Boolean,
    default: false
  },
  center: {
    type: Boolean,
    default: false
  },
  loader: {
    type: Boolean,
    default: false
  }
};

export default {
  props,
  data() {
    return {
      srcNotFound: false,
      showLoader: true
    }
  },
  computed: {
    computedSrc: function () {
      if (this.srcNotFound)
          return this.defaultImage;
      return this.src ? Config.agricolusServerUrl + '/images/resize/' + this.src
          + '?width=' + this.width
          + '&height=' + this.height
          + '&format=' + this.format
          + '&stretch=' + this.stretch
          + '&crop=' + this.crop
          + '&cropfrom=' + this.cropfrom
          + '&quality=' + this.quality : this.defaultImage;
    },
    computedLoader: function() {
      return this.loader && this.showLoader && this.src;
    }
  },
  methods: {
    srcLoaded: function(evt) {
      if (evt.target.src.search(this.defaultImage) < 0){
        this.$emit('src-replaced');
        this.showLoader = false;
      }
    },
    srcError:  function(evt) {
      this.srcNotFound = true;
      this.showLoader = false;
    }
  }
};
</script>
