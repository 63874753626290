import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { getStoreState } from "@/store";
import Config from "@/config";
import { localizeFunction } from "@/filters/localize";
import iziToast from "izitoast";
import { baseRestService } from "@/services/_base";
import { securityService, localizationService } from "@/services";
import { uiStoreGetters } from "@/store/ui.store";

declare var VERSION;
declare var window: any;

@Component({
  components: {}
})
export default class Header extends Vue {
  localizationGroup = "language";
  userIsAuthenticated = false;
  username = null;
  organizationIds: any = [];
  organizationName = null;
  updateNotificationShown: boolean = false;

  get farms() {
    return this.$store.state.farms.farms;
  }

  get isFirstField() {
    return this.farms && this.farms.length === 0;
  }

  get showGlobalFilter() {
    if (this.$route.meta.globalFilter == false || this.isFirstField) return false;
    return true;
  }

  get groupsVisible() {
    return uiStoreGetters.getGroupDefinitionsNumber() > 0;
  }

  get getLanguage() {
    return getStoreState().localization.currentLanguage;
  }

  get getSupportedLanguage() {
    return getStoreState().localization.supportedLanguages;
  }

  get softwareVersion() {
    return "SmartMeteo Web V." + VERSION;
  }

  getSoftwareVersion() {
    return VERSION;
  }

  localize(value: string) {
    return localizeFunction(value);
  }

  setLanguage(language) {
    // todo: localizationde
    localizationService.currentLanguage = language;
    window.location = window.location;
  }

  languageFormatter(lang) {
    if (lang) return Config.languages.formatter[lang];
  }

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("sidebar-hidden");
  }

  sidebarMinimize(e) {
    e.preventDefault();
    document.body.classList.toggle("sidebar-minimized");
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
  }

  asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("aside-menu-hidden");
  }

  showVersion() {
    console.log("Software Version:", VERSION, baseRestService.getAuthenticationToken().access_token);
  }

  signOut() {
    securityService.Logout();
  }

  signIn() {
    securityService.startSignIn();
  }

  async created() {
    this.userIsAuthenticated = await securityService.isUserAuthenticated();
    this.username = await securityService.getUsername();

    function updateReady() {
      // Show update notification only once.
      if (this.updateNotificationShown) return;
      console.log("SmartMeteo update ready !");

      iziToast.question({
        title: localizeFunction("update_ready"),
        timeout: 1000,
        close: true,
        overlay: false,
        displayMode: "once",
        zindex: 999,
        message: localizeFunction("question_update_now"),
        position: "topCenter",
        buttons: [
          [
            "<button><b>" + localizeFunction("Ok") + "</b></button>",
            (instance: any, toast) => {
              window.location = Config.clientSideUrl;
              if(instance) instance.hide({ transitionOut: "fadeOut" });
            },
            true
          ]
        ]
      } as any);

      // mark notification as shown
      this.updateNotificationShown = true;
    }


    // window.applicationCache.addEventListener("updateready", updateReady);
    // if (window.applicationCache.status === window.applicationCache.UPDATEREADY) updateReady();

    // Check for update every 5 minutes

    /**
     * setInterval(() => window.applicationCache.update(), 300000);
     * Error: Uncaught DOMException: Failed to execute 'update' on 'ApplicationCache': there is no application cache to update.
     * Fixato in: setInterval(() => window.location.update(), 300000);
    */
    setInterval(() => {
      if (window.applicationCache && window.applicationCache.update)
        window.applicationCache.update()
    }, 300000);
  }
}
