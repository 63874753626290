import { join, fillArray } from "@/store"
import { weatherStationIntersection, WeatherStationIntersection } from "@/store/intersections/weatherstation.intersection";
import { CreateActionsHandler, CreateGettersHandler } from "./UTILS";
import Vue from "vue";


export interface WeatherStoreModel {
  _stations: Map<string, server.station>;
  _filteredStations: Map<string, server.station>;
  _stationsForecast: Map<string, any>;
  _weatherMeasures: Map<number, server.measureDetail>;
  _weatherDailyMeasures: Map<number, server.dailyMeasureDetail>;
  _summary: Map<string, server.summary>,

  stations: server.station[];
  filteredStations: server.station[];
  stationsForecast: any[],
  weatherMeasures: server.measureDetail[],
  dailyWeatherMeasure: server.dailyMeasureDetail[],
  currentStationMeasure: server.measureDetail[] | server.dailyMeasureDetail[],
  summary: server.summary[];
}

// Actions Interface
interface WeatherStoreActions {
  setStation: (station: server.station) => any
  setStations: (stations: server.station[]) => any
  setFilteredStations: (stations: server.station[]) => any
  setStationSummary: (data: { stationId: string, summary: server.summary }) => any
  setStationForecast: (data: { stationId: string, forecasts: any }) => any
  setWeatherMeasure: (data: server.measureDetail) => any
  setDailyWeatherMeasure: (data: server.dailyMeasureDetail) => any
  removeStation: (stationId: string) => any
  setCurrentMeasure(data: server.measureDetail[] | server.dailyMeasureDetail[])
  removeCurrentMeasure: (stationId: string) => any
}

// Getters Interface
interface WeatherStoreGetters {
  station: (id) => WeatherStationIntersection;
  stations: () => WeatherStationIntersection[];
  filteredStations: () => WeatherStationIntersection[];
  summary: (id) => server.summary;
  forecast: (id) => any;
  lastMeasure: (id) => server.measureDetail;
  lastDailyMeasure: (id) => server.dailyMeasureDetail;
  getCurrentMeasure: (id) => server.measureDetail[] | server.dailyMeasureDetail[];
}

export const weatherStore = {
  PREFIX: "weather",
  namespaced: true,
  state: {
    _stations: new Map<string, server.station>(),
    _filteredStations: new Map<string, server.station>(),
    _stationsForecast: new Map<string, any>(),
    _weatherMeasures: new Map<number, server.measureDetail>(),
    _weatherDailyMeasures: new Map<number, server.dailyMeasureDetail>(),
    _summary: new Map<string, server.summary>(),
    stations: [],
    stationsForecast: [],
    filteredStations: [],
    weatherMeasures: [],
    dailyWeatherMeasure: [],
    currentStationMeasure: [],
    summary: [],
  },
  getters: {
    station: (state: WeatherStoreModel) => (id): server.station => state.stations.find(f => f.id == id),
    summary: (state: WeatherStoreModel) => (id): server.summary => state.summary.find(f => f.idStation == id),
    stations: (state: WeatherStoreModel) => (): server.station[] => state.stations,
    filteredStations: (state: WeatherStoreModel) => (): server.station[] => {
      if (state.filteredStations == null) return state.stations;
      return state.filteredStations;
    },
    forecast: (state: WeatherStoreModel) => (id): any => {
      let result = state.stationsForecast.find(i => i.id == id);
      if (result) {
        return result.forecast;
      }
      return null;
    },
    lastMeasure: (state: WeatherStoreModel) => (id): server.measureDetail => {
      let result = state.weatherMeasures.filter((m: server.measureDetail) => m.idStation == id).sort((a, b) => a.id - b.id);
      if (result && result.length > 0) return result[0];
      return null;
    },
    lastDailyMeasure: (state: WeatherStoreModel) => (id): server.dailyMeasureDetail => {
      return state.dailyWeatherMeasure.find(i => i.idStation == id);
    },
    getCurrentMeasure: (state: WeatherStoreModel) => (id): server.measureDetail[] | server.dailyMeasureDetail[] => {
      function clean(obj): server.measureDetail | server.dailyMeasureDetail { //Remove null attributes from an Object
        for (var pName in obj) {
          if (obj[pName] === null || obj[pName] === undefined) {
            delete obj[pName];
          }
        }
        return obj;
      }

      return state.currentStationMeasure.filter(f => f.idStation == id).map(m => clean(m));
    },
  },
  mutations: {
    setStation(state, station: server.station) {
      state._stations.set(station.id, join(station)(weatherStationIntersection));
      fillArray(state.stations, state._stations.values());
    },
    setStationSummary(state: WeatherStoreModel, data: { stationId: string, summary: server.summary }) {
      state._summary.set(data.stationId, data.summary);
      fillArray(state.summary, state._summary.values());
    },
    setStations(state, stations: server.station[]) {
      state._stations = new Map<string, server.station>();
      for (const key in stations) {
        const station = stations[key];

        state._stations.set(station.id, join(station)(weatherStationIntersection));
      }
      fillArray(state.stations, state._stations.values());
    },
    setFilteredStations(state, stations: server.station[]) {
      state._filteredStations = new Map<string, server.station>();
      for (const key in stations) {
        const station = stations[key];

        state._filteredStations.set(station.id, join(station)(weatherStationIntersection));
      }
      fillArray(state.filteredStations, state._filteredStations.values());
    },
    setStationForecast(state, data) {
      state._stationsForecast.set(data.id, Object.freeze(data));
      fillArray(state.stationsForecast, state._stationsForecast.values());
    },
    setWeatherMeasure(state, data: server.measureDetail) {
      state._weatherMeasures.set(data.id, Object.freeze(data));
      fillArray(state.weatherMeasures, state._weatherMeasures.values());
    },
    setDailyWeatherMeasure(state, data: server.measureDetail) {

      state._weatherDailyMeasures.set(data.idStation, Object.freeze(data));
      fillArray(state.dailyWeatherMeasure, state._weatherDailyMeasures.values());
    },
    removeStation(state, stationId: string) {
      state._stations.delete(stationId);
      fillArray(state.stations, state._stations.values());
    },
    setCurrentMeasure(state, data: server.measureDetail[] | server.dailyMeasureDetail[]) {
      fillArray(state.currentStationMeasure, data);
    },
    removeCurrentMeasure(state, stationId: string) {
      fillArray(state.currentStationMeasure, []);
    },
  },
  actions: {
    setStationSummary(context, data: { stationId: string, summary: server.summary }) {
      context.commit("setStationSummary", data);
    },
    setStation(context, station: server.station) {
      context.commit("setStation", station);
    },
    setStations(context, stations: server.station[]) {
      context.commit("setStations", stations);
    },
    setFilteredStations(context, stations: server.station[]) {
      context.commit("setFilteredStations", stations);
    },
    setStationForecast(context, data: { stationId: string, forecasts: any }) {
      context.commit("setStationForecast", { id: data.stationId, forecast: data.forecasts });
    },
    setWeatherMeasure(context, data: server.measureDetail) {
      context.commit("setWeatherMeasure", data);
    },
    setDailyWeatherMeasure(context, data: server.dailyMeasureDetail) {
      context.commit("setDailyWeatherMeasure", data);
    },
    removeStation(context, stationId: string) {
      context.commit("removeStation", stationId);
    },
    setCurrentMeasure(context, data: server.measureDetail[] | server.dailyMeasureDetail[]) {
      context.commit("setCurrentMeasure", data);
    },
    removeCurrentMeasure(context, stationId: string) {
      context.commit("removeCurrentMeasure", stationId);
    },
  }
}
export const weatherStoreActions = CreateActionsHandler<WeatherStoreActions>(weatherStore);
export const weatherStoreGetters = CreateGettersHandler<WeatherStoreGetters>(weatherStore);

