import Vue from "vue";
import Component from "vue-class-component";
import { securityService } from '@/services'
import Qs from "qs";
import Config from "@/config";

@Component({})
export default class AuthRedirect extends Vue {
  async created() {


    securityService.checkAuthenticationResponse();

    try {
      await securityService.getUserInfo();
    } catch{
      // le autenticazioni non funzionano..
      // esco e trono al flow normale.
      window.location.hash = Config.clientSideUrl;
    }

    var nextRoute = await securityService.getUserRequestedPath();
    if (nextRoute) {
      window.location.href = nextRoute;
      // this.$router.push({ path: nextRoute });
    }
    else {
      this.$router.push({ name: 'Home' })
    }
  }
}
