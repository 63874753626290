import { baseRestService } from "@/services/_base/baseRestService";
import Config from "@/config"
import { bulletinsStoreActions } from "@/store/bulletins.store";

class BulletinsService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = Config.appServerUrl;
  }

  public async List(arealId?: string, productionChainId?: string, archived?: boolean, skip?: number, take?: number): Promise<server.bulletin[]> {
    var result = await this.Get<server.bulletin[]>("/meteo/bulletins", { skip: skip ?? 0, take: take, archived: archived, arealId: arealId, productionChainId: productionChainId });
    bulletinsStoreActions.setBulletins(result);
    return result;
  }

  public async GetBulletin(bulletinId: string): Promise<server.bulletin> {
    var result = await this.Get<server.bulletin>(`/meteo/bulletins/${bulletinId}`);
    bulletinsStoreActions.setBulletin(result);
    return result;
  }

  public async SaveNew(bulletin: server.bulletin): Promise<server.bulletin> {
    var result = await this.Post<server.bulletin>("/meteo/bulletins", bulletin);
    bulletinsStoreActions.setBulletin(result);
    return result;
  }

  public async SetData(bulletinId: string, bulletin: server.bulletin): Promise<server.bulletin> {
    var result = await this.Put<server.bulletin>(`/meteo/bulletins`, bulletin);
    bulletinsStoreActions.setBulletin(result);
    return result;
  }

  public async DeleteBulletin(bulletinId: string): Promise<any> {
    var result = await this.Delete<any>(`/meteo/bulletins/${bulletinId}`);
    bulletinsStoreActions.removeBulletin(bulletinId);
    return result;
  }
}
export const bulletinsService = new BulletinsService();
