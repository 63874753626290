<template>
  <li class="nav-item nav-dropdown" :class="{open: open}">
    <a class="nav-link" @click="toggle">
      <i class="glyphicon glyphicon-menu-down" v-if="!open"></i>
      <i class="glyphicon glyphicon-menu-up" v-if="open && !childSelected"></i>
      <i class="glyphicon glyphicon-menu-up" v-if="childSelected"></i>
      {{ name | localize }}
    </a>
    <!-- <transition @enter="enter" @leave="leave"> -->
      <ul class="nav" v-show="open">
        <slot></slot>
      </ul>
    <!-- </transition> -->
  </li>
</template>
<script>
export default {
  props: {
    routesNames: {default: () => []},
    name: { default: "" }
  },
  data: function() {
    return {
      open: false
    };
  },
  computed: {
    childSelected() {
      let found = !!this.$route.matched.find((r) => {
        return r.meta && r.meta.basename && this.routesNames.indexOf(r.meta.basename) >= 0;
      })
      return found;
    }
  },
  methods: {
    //transizioni???
    // enter(el, done) {
    // },
    // leave(el, done) {
    // },
    toggle() {
      if (this.childSelected) return;
      this.open = !this.open
    }
  },
  mounted() {
    this.open = this.childSelected;
  }
};
</script>

