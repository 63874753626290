import Vue from "vue";
import md5 from "md5";
import Config from "@/config";

function gravatarFunction(email) {
    // if (email)
    //     return "https://www.gravatar.com/avatar/" + md5(email) + "?d=" + encodeURIComponent(Config.clientSideUrl + "/assets/img/avatars/user.png");
    // return null;
    return Config.clientSideUrl + "/assets/img/avatars/user.png";
    M
}
Vue.filter("gravatar", gravatarFunction);